
export const safetyActionTypes = {

    SAFETY_CLICKED: "SAFETY_CLICKED",

    SAFETY_PRESIGNED_URL: 'SAFETY_PRESIGNED_URL',
    SAFETY_PRESIGNED_URL_PENDING: 'SAFETY_PRESIGNED_URL_PENDING',
    SAFETY_PRESIGNED_URL_FULFILLED: 'SAFETY_PRESIGNED_URL_FULFILLED',

    UPLOAD_IMAGE_TO_S3: 'UPLOAD_IMAGE_TO_S3',
    UPLOAD_IMAGE_TO_S3_PENDING: 'UPLOAD_IMAGE_TO_S3_PENDING',
    UPLOAD_IMAGE_TO_S3_FULFILLED: 'UPLOAD_IMAGE_TO_S3_FULFILLED',

    ADD_VEHICLE_SAFETY: 'ADD_VEHICLE_SAFETY',
    ADD_VEHICLE_SAFETY_PENDING: 'ADD_VEHICLE_SAFETY_PENDING',
    ADD_VEHICLE_SAFETY_FULFILLED: 'ADD_VEHICLE_SAFETY_FULFILLED',

    ADD_GOLF_CART_SAFETY: 'ADD_GOLF_CART_SAFETY',
    ADD_GOLF_CART_SAFETY_PENDING: 'ADD_GOLF_CART_SAFETY_PENDING',
    ADD_GOLF_CART_SAFETY_FULFILLED: 'ADD_GOLF_CART_SAFETY_FULFILLED',

    ADD_TRAILER_SAFETY: 'ADD_TRAILER_SAFETY',
    ADD_TRAILER_SAFETY_PENDING: 'ADD_TRAILER_SAFETY_PENDING',
    ADD_TRAILER_SAFETY_FULFILLED: 'ADD_TRAILER_SAFETY_FULFILLED',

    SAFETY_UNIT_MANAGEMENT_LIST:'SAFETY_UNIT_MANAGEMENT_LIST',
    SAFETY_UNIT_MANAGEMENT_LIST_PENDING:'SAFETY_UNIT_MANAGEMENT_LIST_PENDING',
    SAFETY_UNIT_MANAGEMENT_LIST_FULFILLED:'SAFETY_UNIT_MANAGEMENT_LIST_FULFILLED',

    ADD_SAFETY_UNIT: 'ADD_SAFETY_UNIT',
    ADD_SAFETY_UNIT_PENDING: 'ADD_SAFETY_UNIT_PENDING',
    ADD_SAFETY_UNIT_FULFILLED: 'ADD_SAFETY_UNIT_FULFILLED',

    GET_SINGLE_UNIT: 'GET_SINGLE_UNIT',
    GET_SINGLE_UNIT_PENDING: 'GET_SINGLE_UNIT_PENDING',
    GET_SINGLE_UNIT_FULFILLED: 'GET_SINGLE_UNIT_FULFILLED',

    UPDATE_SAFETY_UNIT: 'UPDATE_SAFETY_UNIT',
    UPDATE_SAFETY_UNIT_PENDING: 'UPDATE_SAFETY_UNIT_PENDING',
    UPDATE_SAFETY_UNIT_FULFILLED: 'UPDATE_SAFETY_UNIT_FULFILLED',

    SAFETY_UNIT_FORM_CLICKED: "SAFETY_UNIT_FORM_CLICKED",

    SAFETY_UNIT_LIST_PAGE_CLICKED : 'SAFETY_UNIT_LIST_PAGE_CLICKED',

    DELETE_SAFETY_UNIT: 'DELETE_SAFETY_UNIT',
    DELETE_SAFETY_UNIT_PENDING: 'DELETE_SAFETY_UNIT_PENDING',
    DELETE_SAFETY_UNIT_FULFILLED: 'DELETE_SAFETY_UNIT_FULFILLED',

    GET_UNIT_NUMBER: 'GET_UNIT_NUMBER',
    GET_UNIT_NUMBER_PENDING: 'GET_UNIT_NUMBER_PENDING',
    GET_UNIT_NUMBER_FULFILLED: 'GET_UNIT_NUMBER_FULFILLED',

}


