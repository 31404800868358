import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import AddUnitManagement from '../../../../component/SafetyComponent/UnitManagementComponent/AddUnitManagement/AddUnitManagement';
const cloneDeep = require('clone-deep');

class AddUnitManagementContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.unitId) {
            this.props.getSingleUnit(this.props.match.params.unitId);
        }
    }

    addNewInventoryItem = (values) => {
        let value = cloneDeep(values);
        value.data.year =  value.data.year.year

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.unitId) {
            if(!value.data.year){
                value.data.year = values.data.year.getFullYear()
            }
            this.props.updateUnitForm(value, this.props.match.params.unitId);

        } else {

            this.props.addUnitForm(value);

        }
    }

    onCancelClicked = () => {
        this.props.history.goBack();
    }

    render() {

        return (
            <AddUnitManagement
                {...this.props}
                onSubmit={this.addNewInventoryItem}
                onCancelClicked={this.onCancelClicked}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.safetyReducer.isLoading,
        isEditSafetyUnit: state.safetyReducer.isEditSafetyUnit,
        isUpdateSafetyUnit: state.safetyReducer.isUpdateSafetyUnit,
        singleUnitData: state.safetyReducer.singleUnitData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addUnitForm: (data) => dispatch(actions.addUnitForm(data)),
        updateUnitForm: (data, id) => dispatch(actions.updateUnitForm(data, id)),
        getSingleUnit: (id) => dispatch(actions.getSingleUnit(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUnitManagementContainer);