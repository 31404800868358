import React, { useState, useEffect } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import "react-datepicker/dist/react-datepicker.css";
import { safetyColor, safetyGoodOrBad, safetyYesOrNo } from '../../../../utility/constants/constants';
import { Input } from '../../UI/InputElement/InputElement';
import { vehicleSafetyFormValidator as validate } from '../../../../utility/validator/validator';
import { connect, useDispatch, useSelector } from 'react-redux';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import * as Scroll from 'react-scroll';
import { convertDateToPickerFormat, getUser } from '../../../../utility/utility';
import storage from '../../../../utility/storage';

var scroller = Scroll.scroller;

const getAccountsDropDown = (props) => {

    return props.usersListBySelectedRole && props.usersListBySelectedRole.map(account => {
        if(account.role === 'frontline'){
            return {
                value: account.id,
                label: `${account.first_name} ${account.last_name}`,
                email: account.primary_email ? account.primary_email :  account.secondary_email ? account.secondary_email  : ''
            }
        }else{
            return {
                value: account.id,
                label: `${account.first_name} ${account.last_name}`,
                email: account.email
            }
        }
        
    })
}

const getUnitsDropDown = (props) => {

    return props.units && props.units.map(unit => {
        return {
            id: unit.id,
            name: unit.unit_number,
        }
    })
}

let VehicleFormComponent = (props) => {

    const user = storage.get('user');

    const dispatch = useDispatch()
     const userEmail = useSelector((state) => state.safetyReducer.userEmail)

    const base64Clicked = (i, urlLength) => {
        setIsImageGallery(true);
        setStartIndex((urlLength + i));
    }

    const photoUrlClicked = (i) => {

        setIsImageGallery(true);
        setStartIndex(i);
    }

    const [accounts, setAccounts] = useState(getAccountsDropDown(props));

    useEffect(() => {
        setAccounts(getAccountsDropDown(props))
    }, [props.usersListBySelectedRole])


    const noAccountOptionsMessage = (a, b) => {
        return 'Employee not found';
    }

    //units

    const [units, setUnits] = useState(getUnitsDropDown(props));

    useEffect(() => {
        setUnits(getUnitsDropDown(props))
    }, [props.units])


    const noUnitsOptionsMessage = (a, b) => {
        return 'Units not found';
    }

    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [email, setEmail] = useState()
    const { handleSubmit, pristine, reset, submitting, error, change } = props;

     useEffect(() => {
            setEmail(user.email)
        },[userEmail])

    const clearDescriptionField = (event, fieldName) => {
        if (event.target.value == 'false') {
            dispatch(change(`data[${fieldName}]`, ''))
            // dispatch(initialize('golf_cart_inspection'))
        }
    }

    const seatOnChangeHandler = (event, filedValue, fieldName) => {
        if(event.target.name == 'data[vehicle_body]'){
            if (event.target.value == 'true' && filedValue == 'false') {
                dispatch(change(`data[${fieldName}]`, ''))
                // dispatch(initialize('golf_cart_inspection'))
            }
        }else{
            if (event.target.value == 'false' && filedValue == 'true') {
                dispatch(change(`data[${fieldName}]`, ''))
                // dispatch(initialize('golf_cart_inspection'))
            }
        }
       
    }

    const findUserEmail = (currentUser) =>{
       setEmail(currentUser.email)
    }

    return (
        <>
            {
                isImageGallery && <ModalImageGallery
                    isImageGallery={isImageGallery}
                    setIsImageGallery={setIsImageGallery}
                    startIndex={startIndex}
                    base64={props.portfolioImages}
                    imageUrls={props.savedPortfolioURls}
                />
            }
            <main className="app-content wraper_content user_newQuote">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluid">
                        <div className="pct_form ticket_form">
                            <h2 className="md_title" data-test="create_ticket_header">{'Vehicle Inspection Report'}</h2>
                            <div className="addQuote_form" >
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Date <sup className="cst_sup_txt text_danger">*</sup></label>

                                            <div className="input-group cst_input_group">
                                                <Input name="data[date]"
                                                    dataTest="ticket_time_picker"
                                                    preventReadOnly={true}
                                                    dateFormat="MM-dd-yyyy" id="from_date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier test1" />
                                                <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }} onClick={e => document.querySelector(".test1").click()}>
                                                    <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar" alt="Calendar Icon" ></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label name="data[employee_id]" className="form_title">Employee Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input instanceId="job_select" name="data[employee_id]" onChange={(value) => findUserEmail(value)} noOptionsMessage={noAccountOptionsMessage} type="inputSelect" options={accounts} />
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Employee Email <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <input
                                                    dataTest="first_name_data"
                                                    disabled={true}
                                                    value={email}
                                                    type="text"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[employee_email]"
                                                    id='vehicleEmail'
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Unit <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[unit_id]" className="custom-select input-modifier" type="select" options={units} />
                                        </div>

                                    </div>


                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Mileage <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <Input
                                                    dataTest="first_name_data"
                                                    type="number"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[mileage]"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Fuel Card <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[fuel_card]" className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Seat Belt <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[seat_belt]" className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Car Wash  <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[car_wash]" className="custom-select input-modifier" type="select" selectType='inputColorSelect' options={safetyColor} />
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Head Lamps <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[head_lamps]" onChange={(value) => clearDescriptionField(value, 'description_head_lamps')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Brake Lights <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[brake_lights]" onChange={(value) => clearDescriptionField(value, 'description_brake_lights')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.head_lamps == 'false' || props.formStates?.data?.brake_lights == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.head_lamps == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Head Lamps <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_head_lamps]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.brake_lights == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Brake Lights <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_brake_lights]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Turn Indicators <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[turn_indicators]" onChange={(value) => clearDescriptionField(value, 'description_turn_indicators')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Tail Lamps <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[tail_lamps]" onChange={(value) => clearDescriptionField(value, 'description_tail_lamps')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.turn_indicators == 'false' || props.formStates?.data?.tail_lamps == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.turn_indicators == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Turn Indicators <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_turn_indicators]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.tail_lamps == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Tail Lamps <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_tail_lamps]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Transmission <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[transmission]" onChange={(value) => clearDescriptionField(value, 'description_transmission')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Leaks <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[leaks]" onChange={(value) => clearDescriptionField(value, 'description_leaks')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.transmission == 'false' || props.formStates?.data?.leaks == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.transmission == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Transmission <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_transmission]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.leaks == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Leaks <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_leaks]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Tire Pres/Wear <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[tire_pres_wear]" onChange={(value) => clearDescriptionField(value, 'description_tire_pres_wear')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Mirrors <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[mirrors]" onChange={(value) => clearDescriptionField(value, 'description_mirrors')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.tire_pres_wear == 'false' || props.formStates?.data?.mirrors == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.tire_pres_wear == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Tire Pres/Wear Repairs <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_tire_pres_wear]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.mirrors == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Mirror Repairs <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_mirrors]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Instruments<sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[instruments]" onChange={(value) => clearDescriptionField(value, 'description_instruments')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Horn <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[horn]" onChange={(value) => clearDescriptionField(value, 'description_horn')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.instruments == 'false' || props.formStates?.data?.horn == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.instruments == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Instruments Repair <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_instruments]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.horn == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Horn Repair <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_horn]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Windows <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[windows]" onChange={(value) => clearDescriptionField(value, 'description_windows')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Upholstery <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[upholstery]" onChange={(value) => clearDescriptionField(value, 'description_upholstery')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.windows == 'false' || props.formStates?.data?.upholstery == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.windows == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Window Repairs <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_windows]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.upholstery == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Upholstery Repair <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_upholstery]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Tools/Jack <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[tools_jack]" onChange={(value) => clearDescriptionField(value, 'description_tools_jack')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Accident Form <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[accident_form]" onChange={(value) => clearDescriptionField(value, 'description_accident_form')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.tools_jack == 'false' || props.formStates?.data?.accident_form == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.tools_jack == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Tools/Jack Repair <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_tools_jack]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.accident_form == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Accident Form <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_accident_form]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Insurance / Reg <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[insurance_reg]" onChange={(value) => clearDescriptionField(value, 'description_insurance_reg')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Enterprise Card <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[enterprise_card]" onChange={(value) => clearDescriptionField(value, 'description_enterprise_card')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.insurance_reg == 'false' || props.formStates?.data?.enterprise_card == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.insurance_reg == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Insurance/Reg <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_insurance_reg]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.enterprise_card == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe Enterprise Card <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_enterprise_card]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Vehicle Body <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[vehicle_body]" onChange={(value) => seatOnChangeHandler(value, props.formStates?.data?.jump_starter, 'additional_notes')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Does vehicle have Portable jump starter? <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[jump_starter]" onChange={(value) => seatOnChangeHandler(value, props.formStates?.data?.vehicle_body, 'additional_notes')} className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.vehicle_body == 'false' || props.formStates?.data?.jump_starter == 'true' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-12`}>
                                                <label className="form_title">Additional Notes <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="textarea" name="data[additional_notes]" className="form-control textarea_modifier" rows="3" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Any service engine light on? <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[engine_light]" onChange={(value) => clearDescriptionField(value, 'description_engine_light_on')} className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.engine_light == 'true' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <label className="form_title">Describe the problem <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="textarea" name="data[description_engine_light_on]" className="form-control textarea_modifier" rows="3" />
                                            </div>
                                            <div className="col-md-6">
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <div className="checkbox_block radius_checkbox">
                                                <div className="custom-control custom-checkbox custom-control-inline mt-3">
                                                    <Input
                                                        type="checkbox"
                                                        name="data[email_preference]"
                                                        className="custom-control-input"
                                                        id="customCheck2"
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        for="customCheck2"
                                                    >
                                                        Send me a copy of my responses
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {props.formStates?.data?.email_preference ? <div className="row">
                                        <div className="form-group col-md-6">
                                            <label for="emailaddress" className="form_title">
                                                Email <sup className="cst_sup_txt text_danger">*</sup>
                                            </label>
                                            <Input
                                                dataTest="email_data"
                                                type="text"
                                                name="data[emailaddress]"
                                                className="form-control input-modifier"
                                                placeholder=""
                                            />
                                        </div>
                                    </div> : null} */}


                                    <div className="file_attachment">
                                        <Input type="file" name="attachments" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                        <a href="javascript:void(0)" onClick={props.choosePhotoClicked} className="file_title"><img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments</a>
                                        <p className="size_dec">Upload 4 photos of the Front View, Back View and Both Sides</p>

                                        <div class="upload_file">
                                            <div class="row">
                                                {props.savedPortfolioURls.map((url, i) => {
                                                    return (
                                                        <div class="col-2 col-sm-2 cst_gutter" key={i}>
                                                            <div class="file_view" onClick={() => photoUrlClicked(i)}>
                                                                <ImageLoader
                                                                    style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                    src={url.photo_urls.small}
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                                </ImageLoader>
                                                            </div>
                                                            <div class="cancel_icon">
                                                                <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, i)} alt="Image" />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {props.portfolioImages.map((url, i) => {
                                                    return (
                                                        <div class="col-2 col-sm-2 cst_gutter" key={i} >
                                                            <div class="file_view">
                                                                <img src={url} alt="Image" onClick={() => base64Clicked(i, props.savedPortfolioURls.length)} />
                                                                <div class="cancel_icon">
                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group btn_block mb-0">
                                        <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>{props.isLoading ? "" : "Submit"}</button>
                                        {/* <button data-test="cancel_button" type="button" onClick={() => props.onCancelClicked()} className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </main>
        </>
    )

    // }
}
const initialDate = convertDateToPickerFormat(new Date());

const mapStateToProps = (state) => {
    let data = {};
    const user = getUser()
    data = {
        date: initialDate,
        employee_id: {value : user.id, label : user.name},
        unit_id: "",
        mileage: "",
        car_wash: "",
        fuel_card: "",
        seat_belt: "",
        head_lamps: "",
        brake_lights: "",
        turn_indicators: "",
        tail_lamps: "",
        transmission: "",
        leaks: "",
        tire_pres_wear: "",
        mirrors: "",
        instruments: "",
        horn: "",
        windows: "",
        upholstery: "",
        tools_jack: "",
        accident_form: "",
        insurance_reg: "",
        enterprise_card: "",
        vehicle_body: "",
        engine_light: "",
        jump_starter: "",
    }
    return { initialValues: { data }, formStates: getFormValues('vehicle_inspection')(state) }

}

VehicleFormComponent = reduxForm({
    form: 'vehicle_inspection',
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(VehicleFormComponent);

VehicleFormComponent = connect(mapStateToProps)(VehicleFormComponent)

export const scrollToFirstError = (errors) => {

    const keys = [
        "date", "employee_id", "unit_id", "mileage", "car_wash", "fuel_card",
        "seat_belt", "head_lamps", "brake_lights", "turn_indicators",
        "tail_lamps", "transmission", "leaks", "tire_pres_wear", "mirrors",
        "instruments", "horn", "windows", "upholstery", "tools_jack",
        "accident_form", "insurance_reg", "enterprise_card", "vehicle_body",
        "engine_light", "jump_starter", "emailaddress",
        "description_head_lamps", "description_brake_lights",
        "description_turn_indicators", "description_tail_lamps",
        "description_transmission", "description_leaks", "description_tire_pres_wear",
        "description_mirrors", "description_instruments",
        "description_horn", "description_windows", "description_upholstery",
        "description_tools_jack",
        "description_accident_form", "description_insurance_reg",
        "description_enterprise_card", "description_engine_light_on", "additional_notes"
    ];

    let scroolToError = "";

    keys.forEach(key => {
        if (errors.data[key]) {
            scroolToError = `data[${key}]`;
        }
    });
    //  
    scroller.scrollTo(`${scroolToError}`, {
        duration: 500,
        delay: 1,
        isDynamic: true,
        smooth: true,
        offset: -100,
    })

}

export default VehicleFormComponent;