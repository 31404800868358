import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital,convertDateToDifferentTZ, convertDateAndTimeTimezone, unitTableTitleFormat } from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import {  convertDateUTCFormAuditToSelectedTimezone } from '../../../../utility/TimezoneOperations/timezoneUtility';
import storage from '../../../../utility/storage';
var moment = require('moment');

let UnitManagementTable = (props) => {
    const user = storage.get('user')
    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchSelfAudit && state.form.searchSelfAudit.values ? state.form.searchSelfAudit.values : {});
    const DateGenerate = useSelector(state => state.LogBookReducer.dataGenerated);
    const values = useSelector(state => state.auditsReducer.selfAuditList_searchValues);
    const No_of_pages = useSelector(state => state.safetyReducer.safetyUnitManagemnetList_total_pages);
    const No_of_rows = useSelector(state => state.safetyReducer.safetyUnitManagemnetList_no_of_rows);
    const restroomPorteringCurPage = useSelector(state => state.safetyReducer.safetyUnitManagemnetList_curPage);
    const isRestroomPortering = useSelector(state => state.safetyReducer.isSafetyUnitManagementList);
    const SearchOrResetScheduledTaskListClicked = useSelector(state => state.auditsReducer.SearchOrResetScheduledTaskListClicked);
    const {timezone}=useSelector((state)=>state.authReducer.user)
    console.log("ZONE",timezone);
    const accountTimezone = 'America/Los_Angeles';

    const data = props.selfAuditList && props.selfAuditList.map((value,index) => {
        return {
            // id: index + 1,
            unit_id: value ?  value.unit_number : '-',
            year: value ? value?.year  :"-",
            make: value ? value?.make  :"-",
            model: value ? value?.model  :"-",
            reg_state:value ? value?.reg_state  :"-",
            color:value ? value?.color  :"-",
            location:value ? value?.location  :"-",
            type:value ? unitTableTitleFormat(value?.type)  :"-",
            actions : <>
            {user?.user_permissions?.unit_access ? <>
                <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/safety/unit-management/${value.id}`)} data-test="edit_pencil_icon"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                <span className="mr_20 account_edit_icn"  onClick={() => props.handleClickDeleteModel(value)}><i class="fa fa-trash" aria-hidden="true"></i></span>
            </> : null}
            </>
        }
    })

    const columns = [
        {
            Header: <><span>Unit ID </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'unit_id',
            show: props.selfAuditList.length !== 0,
            // width: '15%',
            sortable:false,
        },
        {
            Header: <><span> Year </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'year',
            show: props.selfAuditList.length !== 0,
            // width: '30%',
            sortable:true,
        },
        {
            Header: <><span> Make </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'make',
            show: props.selfAuditList.length !== 0,
            // width: '30%',
            sortable:true,
        },
        {
            Header: <><span> Model </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'model',
            show: props.selfAuditList.length !== 0,
            // width: '30%',
            sortable:true,
        },
        {
            Header: <><span> Reg State </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'reg_state',
            show: props.selfAuditList.length !== 0,
            // width: '30%',
            sortable:true,
        },
        {
            Header: <><span> Color </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'color',
            show: props.selfAuditList.length !== 0,
            // width: '30%',
            sortable:true,
        },
        {
            Header: <><span> Location Of Vehicle </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'location',
            show: props.selfAuditList.length !== 0,
            // width: '30%',
            sortable:true,
        },
        {
            Header: <><span> Type</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'type',
            show: props.selfAuditList.length !== 0,
            // width: '50%',
            sortable:true,
        },
        {
            Header: '',
            accessor: 'actions',
            show: props.selfAuditList.length !== 0,
        },
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    if(restroomPorteringCurPage !== tablePageNew.current) {
        tablePageNew.current = restroomPorteringCurPage;
      }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.safetyUnitListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetScheduledTaskListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isRestroomPortering) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : restroomPorteringCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if(No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                // const from_date = SearchValue.fromDate ? moment(values.fromDate).format('DD-MM-YYYY') : null;
                // const to_date = SearchValue.toDate ? moment(values.toDate).format('DD-MM-YYYY') : null;
                // const accountId = SearchValue.id ? SearchValue.id : null;
                // const taskName = SearchValue.name ? SearchValue.name : null;
                // const companyName = SearchValue.company ? SearchValue.company : null;
                // const accountName = SearchValue.job ? SearchValue.job : null;
                // const from = SearchValue.fromDate ? convertDateToDifferentTZ(SearchValue.fromDate, 'from') : null;
                // const to = SearchValue.toDate ? convertDateToDifferentTZ(SearchValue.toDate, 'to') : null;
                props.getAllSafteyManagemntTable({ page, limit, order, orderBy},)
            }
        }
    }

    return (
        SearchOrResetScheduledTaskListClicked && props.isRestroomPortering ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <div style={{ width: '100%', height: '100%' }}>

            <ReactTable
                data={data}
                loading={props.isRestroomPortering}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={restroomPorteringCurPage}
                page = { restroomPorteringCurPage }
                onPageChange={(page) => {setTablePageFn(page)}}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.selfAuditList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(isRestroomPortering, 'No Unit Management list Found')}
                LoadingComponent={() =>
                    isRestroomPortering ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px',
                                width:"40%"
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
            </div>
    )
}

export default UnitManagementTable;