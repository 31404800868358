import React, { useState, useEffect } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import "react-datepicker/dist/react-datepicker.css";
import { safetyTierPressure, safetyYesOrNo } from '../../../../utility/constants/constants';
import { Input } from '../../UI/InputElement/InputElement';
import { trailerSafetyFormValidator as validate } from '../../../../utility/validator/validator';
import { connect, useDispatch, useSelector } from 'react-redux';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import * as Scroll from 'react-scroll';
import { convertDateToPickerFormat, getUser } from '../../../../utility/utility';
import storage from '../../../../utility/storage';

var scroller = Scroll.scroller;

const getAccountsDropDown = (props) => {

    return props.usersListBySelectedRole && props.usersListBySelectedRole.map(account => {
        if(account.role === 'frontline'){
            return {
                value: account.id,
                label: `${account.first_name} ${account.last_name}`,
                email: account.primary_email ? account.primary_email :  account.secondary_email ? account.secondary_email  : ''
            }
        }else{
            return {
                value: account.id,
                label: `${account.first_name} ${account.last_name}`,
                email: account.email
            }
        }
    })
}

const getUnitsDropDown = (props) => {

    return props.units && props.units.map(unit => {
        return {
            id: unit.id,
            name: unit.unit_number,
        }
    })
}


let TrailerFormComponent = (props) => {

    const user = storage.get('user');


    const dispatch = useDispatch()
    const userEmail = useSelector((state) => state.safetyReducer.userEmail)
    

    const base64Clicked = (i, urlLength) => {
        setIsImageGallery(true);
        setStartIndex((urlLength + i));
    }

    const photoUrlClicked = (i) => {

        setIsImageGallery(true);
        setStartIndex(i);
    }

    const [accounts, setAccounts] = useState(getAccountsDropDown(props));

    useEffect(() => {
        setAccounts(getAccountsDropDown(props))
    }, [props.usersListBySelectedRole])

    //units 

        const [units, setUnits] = useState(getUnitsDropDown(props));
    
        useEffect(() => {
            setUnits(getUnitsDropDown(props))
        }, [props.units])

    const noAccountOptionsMessage = (a, b) => {
        return 'Employee not found';
    }


    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [email, setEmail] = useState()
    const { handleSubmit, pristine, reset, submitting, error, change } = props;

    useEffect(() => {
        setEmail(user.email)
    },[userEmail])

    const clearDescriptionField = () => {
            dispatch(change(`data[right_wheel_psi]`, ''))
            dispatch(change(`data[left_wheel_psi]`, ''))
    }

    const findUserEmail = (currentUser) =>{
        setEmail(currentUser.email)
     }

    return (
        <>
            {
                isImageGallery && <ModalImageGallery
                    isImageGallery={isImageGallery}
                    setIsImageGallery={setIsImageGallery}
                    startIndex={startIndex}
                    base64={props.portfolioImages}
                    imageUrls={props.savedPortfolioURls}
                />
            }
            <main className="app-content wraper_content user_newQuote">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluid">
                        <div className="pct_form ticket_form">
                            <h2 className="md_title" data-test="create_ticket_header">{'Trailer Inspection Report'}</h2>
                            <div className="addQuote_form" >
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Date <sup className="cst_sup_txt text_danger">*</sup></label>

                                            <div className="input-group cst_input_group">
                                                <Input name="data[date]"
                                                    dataTest="ticket_time_picker"
                                                    preventReadOnly={true}
                                                    dateFormat="MM-dd-yyyy" id="from_date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier test1" />
                                                <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }} onClick={e => document.querySelector(".test1").click()}>
                                                    <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar" alt="Calendar Icon" ></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label name="data[employee_id]" className="form_title">Employee Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input instanceId="job_select" name="data[employee_id]" onChange={(value) => findUserEmail(value)} noOptionsMessage={noAccountOptionsMessage} type="inputSelect" options={accounts} />
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Employee Email <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <input
                                                    dataTest="first_name_data"
                                                    disabled={true}
                                                    value={email}
                                                    type="text"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[employee_email]"
                                                    id='TrailerEmail'
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Unit Number <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[unit_id]" className="custom-select input-modifier" type="select" options={units} />
                                        </div>
                                       
                                    </div>


                                    <div className="row">
                                    <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Trailer License plate # <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <Input
                                                    dataTest="first_name_data"
                                                    type="text"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[trailer_license_plate]"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Generator Hours <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <Input
                                                    dataTest="first_name_data"
                                                    type="number"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[generator_hours]"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>


                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Turn Signals <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[turn_signals]" className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Tail Lights <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[tail_lights]" className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Tire Pressure <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[tire_pressure]" onChange={() => clearDescriptionField()} className="custom-select input-modifier" type="select" options={safetyTierPressure} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Insurance <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[insurance]" className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.tire_pressure ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            {
                                                props.formStates?.data?.tire_pressure == 'right_wheel_psi' ? <div className={`form-group col-md-6`}>
                                                    <div className={props.formStates?.data?.tire_pressure == 'right_wheel_psi' ? `showSafetyField` : 'hideSafetyField'}>
                                                        <label className="form_title">Right Wheel PSI <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input type="textarea" name="data[right_wheel_psi]" className="form-control textarea_modifier" rows="3" />
                                                    </div>
                                                </div> :
                                                    <div className={`form-group col-md-6`}>
                                                        <div className={props.formStates?.data?.tire_pressure == 'left_wheel_psi' ? `showSafetyField` : 'hideSafetyField'}>
                                                            <label className="form_title">Left Wheel PSI <sup className="cst_sup_txt text_danger">*</sup></label>
                                                            <Input type="textarea" name="data[left_wheel_psi]" className="form-control textarea_modifier" rows="3" />
                                                        </div>
                                                    </div>
                                            }

                                            <div className="col-md-6">
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Registration <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[registration]" className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Gas Card <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[gas_card]" className="custom-select input-modifier" type="select" options={safetyYesOrNo} />

                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                    <div className="form-group col-md-6">
                                            <label className="form_title">Brake Lights <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[brake_lights]" className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Tools/ PSI Gage <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[tools_psi_gage]" className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                    </div>


                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <div className="checkbox_block radius_checkbox">
                                                <div className="custom-control custom-checkbox custom-control-inline mt-3">
                                                    <Input
                                                        type="checkbox"
                                                        name="data[email_preference]"
                                                        className="custom-control-input"
                                                        id="customCheck2"
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        for="customCheck2"
                                                    >
                                                        Send me a copy of my responses
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {props.formStates?.data?.email_preference ? <div className="row">
                                        <div className="form-group col-md-6">
                                            <label for="emailaddress" className="form_title">
                                                Email <sup className="cst_sup_txt text_danger">*</sup>
                                            </label>
                                            <Input
                                                dataTest="email_data"
                                                type="text"
                                                name="data[emailaddress]"
                                                className="form-control input-modifier"
                                                placeholder=""
                                            />
                                        </div>
                                    </div> : null} */}


                                    <div className="file_attachment">
                                        <Input type="file" name="attachments" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                        <a href="javascript:void(0)" onClick={props.choosePhotoClicked} className="file_title"><img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments</a>
                                        <p className="size_dec">Please provide 4 images of the vehicle. Front, Back, Left and Right sides.</p>

                                        <div class="upload_file">
                                            <div class="row">
                                                {props.savedPortfolioURls.map((url, i) => {
                                                    return (
                                                        <div class="col-2 col-sm-2 cst_gutter" key={i}>
                                                            <div class="file_view" onClick={() => photoUrlClicked(i)}>
                                                                <ImageLoader
                                                                    style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                    src={url.photo_urls.small}
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                                </ImageLoader>
                                                            </div>
                                                            <div class="cancel_icon">
                                                                <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, i)} alt="Image" />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {props.portfolioImages.map((url, i) => {
                                                    return (
                                                        <div class="col-2 col-sm-2 cst_gutter" key={i} >
                                                            <div class="file_view">
                                                                <img src={url} alt="Image" onClick={() => base64Clicked(i, props.savedPortfolioURls.length)} />
                                                                <div class="cancel_icon">
                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group btn_block mb-0">
                                        <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>{props.isLoading ? "" : "Submit"}</button>
                                        {/* <button data-test="cancel_button" type="button" onClick={() => props.onCancelClicked()} className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </main>
        </>
    )

    // }
}

const initialDate = convertDateToPickerFormat(new Date());

const mapStateToProps = (state) => {
    let data = {};
    const user = getUser()
    data = {
        date: initialDate,
        employee_id: {value : user.id, label : user.name},
        trailer_license_plate: '',
        generator_hours: '',
        brake_lights: '',
        turn_signals: '',
        tail_lights: '',
        tire_pressure: '',
        insurance: '',
        registration: '',
        gas_card: '',
        tools_psi_gage: ''
    }
    return { initialValues: { data }, formStates: getFormValues('trailer_inspection')(state) }

}

TrailerFormComponent = reduxForm({
    form: 'trailer_inspection',
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(TrailerFormComponent);

TrailerFormComponent = connect(mapStateToProps)(TrailerFormComponent)

export const scrollToFirstError = (errors) => {

    const keys = ['date', 'employee_id', 'trailer_license_plate',
        'generator_hours', 'brake_lights', 'turn_signals',
        'tail_lights', 'tire_pressure', 'right_wheel_psi',
        'insurance', 'registration', 'gas_card', 'tools_psi_gage', 'left_wheel_psi', 'emailaddress']

    let scroolToError = "";

    keys.forEach(key => {
        if (errors.data[key]) {
            scroolToError = `data[${key}]`;
        }
    });
    //  
    scroller.scrollTo(`${scroolToError}`, {
        duration: 500,
        delay: 1,
        isDynamic: true,
        smooth: true,
        offset: -100,
    })

}

export default TrailerFormComponent;