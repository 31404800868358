import React, { Component } from 'react';
import { routes } from '../../../../utility/constants/constants';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../utility/storage';
// import SelfAudit from '../../../component/FormAuditModule/AuditsComponent/SelfAudit/SelfAuditPage/selfAudit';
import UnitManagement from '../../../component/SafetyComponent/UnitManagementComponent/UnitManagement';

var moment = require('moment');
const cloneDeep = require('clone-deep');

class UnitManagementContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
    }

    componentWillUnmount() {
        this.props.safetyUnitListPageClicked(0)
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.safetyUnitManagemnetList_no_of_rows
        this.props.reset('searchSafetyUnitManagement');
        this.props.safetyUnitListPageClicked(0)
        this.props.getAllSafteyManagemntTable({page,limit}, {})

    }

    searchSelfAudit = (value) => {
        const values = cloneDeep(value)
        let unit_number =  null;
        let year =  null;
        let make =  null;
        let model = null;
        let reg_state =  null;
        let color =  null;
        let location =  null;
        let type =  null;
    
        const page = 1
        const limit = this.props.safetyUnitManagemnetList_no_of_rows
        if (values) {
            unit_number = values.unit_number || null;
            year = values.year || null;
            make = values.make || null;
            model = values.model || null;
            reg_state = values.reg_state || null;
            color = values.color || null;
            location = values.location || null;
            type = values.type?.value || null;
        }
        console.log(page,limit,unit_number,year,make,model,reg_state,color,location,type,"CCC")
        this.props.getAllSafteyManagemntTable({page,limit,unit_number,year,make,model,reg_state,color,location,type}, values);
    }
    
    
    newTicketClicked = () => {
        this.props.safetyUnitFormClicked()
        this.props.history.push(routes.ADD_UNIT_MANAGEMENT);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    handleClickDeleteModel = (ticket) => {
        this.props.handleClickDeleteModel(ticket);
    }
         
    render() {
        return (
            <UnitManagement
            {...this.props}
            isLoading={this.props.isLoading}
            resetClicked={this.resetClicked}
            selfAuditList={this.props.safetyUnitManagementList ? this.props.safetyUnitManagementList : []}
            newTicketClicked={this.newTicketClicked}
            onSubmit={this.searchSelfAudit}
            handleClickDeleteModel={this.handleClickDeleteModel}
            handleDeleteClose={this.handleDeleteClose}
            openDeleteModel={this.props.openDeleteModel}
            deleteObject={this.props.deleteObject}
            deleteUnitForm={this.props.deleteUnitForm}
            />
        )
    }
}




const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.safetyReducer.isLoading,
        safetyUnitManagementList: state.safetyReducer.safetyUnitManagemnetList,
        safetyUnitManagemnetList_no_of_rows:state.safetyReducer.safetyUnitManagemnetList_no_of_rows,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),  // requires form name
        getAllSafteyManagemntTable: (params , values)=> dispatch(actions.getAllSafteyManagemntTable(params,values)),
        safetyUnitListPageClicked: (page) => dispatch(actions.safetyUnitListPageClicked(page)),
        safetyUnitFormClicked: () => dispatch(actions.safetyUnitFormClicked()),
        handleClickDeleteModel: (ticket) => dispatch(actions.handleClickDeleteModel(ticket)),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        deleteUnitForm: (id) => dispatch(actions.deleteUnitForm(id)),
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(UnitManagementContainer);
