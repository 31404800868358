import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Input } from '../../../UI/InputElement/InputElement';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { getUser, makeFirstLetterCapital } from '../../../../../utility/utility';
import { addUnitManagementValidator as validate } from '../../../../../utility/validator/validator';
import moment from "moment";
import cloneDeep from 'clone-deep';
import { convertDateTimezoneFormAudit, convertDateTimezoneFormAuditReduxForm } from '../../../../../utility/TimezoneOperations/timezoneUtility';
import AddUnitFormSkeleton from './AddUnitSkeleton';
import { safetyGoodOrBad } from '../../../../../utility/constants/constants';

let AddUnitManagement = (props) => {

     const inspectionType = [
        {
            id: 'vehicle',
            name: 'Vehicle'
        },
        {
            id: 'golf_cart',
            name: 'Golf Cart'
        },
        {
            id: 'trailer',
            name: 'Trailer'
        },
    ]

    const getInventoryLocationList = (props) => {
        if (props.inventoryLocationList && props.inventoryLocationList.length > 0) {
            return props.inventoryLocationList && props.inventoryLocationList.map((location) => {
                return {
                    value: location.id,
                    label: makeFirstLetterCapital(location.name)
                }
            })
        } else {
            return []
        }
    };

    const [locationList, setLocationList] = useState(getInventoryLocationList(props));

    useEffect(() => {
        setLocationList(getInventoryLocationList(props))
    }, [props.inventoryLocationList])

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;

    if (props.isUpdateSafetyUnit) {
        return (
            <AddUnitFormSkeleton />
        )
    } else {
        return (
            <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <div className="quote_form ticket_form" style={{ maxWidth: '900px' }}>
                            <div className="row">
                                <div className="col-sm-12 text-center"> <h2 className="md_title" >{props.isEditSafetyUnit ? "Update Unit Management" : "Add New Unit Management"}</h2></div>
                            </div>
                            <div className="addQuote_form">
                                <form className="form_content" onSubmit={handleSubmit} >
                                    <div className="cst_tab_content">


                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Unit Number <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Unit Number" name="data[unit_number]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label className="form_title"> Year <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <div className="input-group cst_input_group col-lg-14 pl-0">
                                                        <div style={{ width: '100%' }}>
                                                            <Input
                                                                type="date"
                                                                datePickerType={"year-picker"}
                                                               dateFormat="YYYY"
                                                                className="form-control input-modifier"
                                                                placeholder="Select Year"
                                                                name="data[year]"
                                                            />
                                                        </div>
                                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                            <div className="form-group">
                                                    <label className="form_title"> Make <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Make" name="data[make]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Model <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Model" name="data[model]" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* New Items */}

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Reg State <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Reg State" name="data[reg_state]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Color <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Color" name="data[color]" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Location of Vehicle <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Location of Vehicle" name="data[location]" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Type <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="ticket_priority" name="data[type]" className="custom-select input-modifier" type="select" options={inspectionType} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} >{props.isLoading ? "" : props.isEditSafetyUnit ? "Update" :"Submit"}</button>
                                        <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

const mapStateToProps = (state) => {

    let data = {};

    if (!isEmpty(state.safetyReducer.singleUnitData)) {
        
        const values = cloneDeep(state.safetyReducer && state.safetyReducer.singleUnitData && state.safetyReducer.singleUnitData.data);
        data = {
            unit_number: values.unit_number,
            year:  new Date(`${values.year}-01-01`),
            make:  values.make,
            model: values.model,
            reg_state:  values.reg_state,
            color:  values.color,
            location: values.location,
            type:  values.type,
         }
    }
    return { initialValues: { data } }

}

AddUnitManagement = reduxForm({
    form: 'AddUnitManagement',
    validate,
    enableReinitialize: true
})(AddUnitManagement);

AddUnitManagement = connect(mapStateToProps)(AddUnitManagement)

export default AddUnitManagement;





