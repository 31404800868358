import { reset } from "redux-form";
import * as API from "../../../API/SafetyAPI/SafetyAPI";
import SuccessAlert from "../../../utility/successAlert/successAlert";
import { safetyActionTypes } from "./SafetyActionType";
import history from "../../../router/history";
import { routes } from "../../../utility/constants/constants";
import { handleDeleteClose } from "../AdminOrSuperAdminActions/AdminOrSuperAdminActions";
import cloneDeep from "clone-deep";
import storage from "../../../utility/storage";


const user = storage.get('user');


export const safetyClicked = () => {
    return {
        type: safetyActionTypes.SAFETY_CLICKED,
    };
};

export const getVehicleSafetyPresignedUrl = (ticket, extentions, arrayBuffer, resetForm) => {
    return (dispatch, getState) => {
        return dispatch({
            type: safetyActionTypes.SAFETY_PRESIGNED_URL,
            payload: API.getSafetyPresignedUrl(extentions, resetForm)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    ticket.data['attachments'] = [];

                    response.data.presigned_urls.forEach((p, i) => {
                        ticket.data.attachments.push(p.attachment_path)

                        dispatch({
                            type: safetyActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(addVehicleSafety(ticket, resetForm));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const addVehicleSafety = (ticket, resetForm) => async (dispatch, getState) => {
    return await dispatch({
        type: safetyActionTypes.ADD_VEHICLE_SAFETY,
        payload: API.addVehicleSafety(ticket).then((response) => {
            if (response.data.success) {
                const email = cloneDeep(getState().safetyReducer.userEmail);
                let ele = document.getElementById('resetVehicleSafetyImage')
                if (ele) {
                    ele.click()
                }
                
                dispatch(reset(resetForm))
                SuccessAlert('Vehicle Inspection Report Created Successfully')
                return {userEmail : email + 1}
            }
        }).catch((error) => {
            console.error(error)
        })
    });
};

export const getGolfCartSafetyPresignedUrl = (ticket, extentions, arrayBuffer, resetForm) => {
    return (dispatch, getState) => {
        return dispatch({
            type: safetyActionTypes.SAFETY_PRESIGNED_URL,
            payload: API.getSafetyPresignedUrl(extentions, resetForm)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    ticket.data['attachments'] = [];

                    response.data.presigned_urls.forEach((p, i) => {
                        ticket.data.attachments.push(p.attachment_path)

                        dispatch({
                            type: safetyActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(addGolfCartSafety(ticket, resetForm));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const addGolfCartSafety = (ticket, resetForm) => async (dispatch, getState) => {
    return await dispatch({
        type: safetyActionTypes.ADD_GOLF_CART_SAFETY,
        payload: API.addGolfCartSafety(ticket).then((response) => {
            if (response.data.success) {
                let ele = document.getElementById('resetGolfCartSafetyImage')
                const email = cloneDeep(getState().safetyReducer.userEmail);
                if (ele) {
                    ele.click()
                }
                
                dispatch(reset(resetForm))
                SuccessAlert('Golf Cart Inspection Report Created Successfully')
                return {userEmail : email + 1}
            }
        }).catch((error) => {
            console.error(error)
        })
    });
};

export const getTrailerSafetyPresignedUrl = (ticket, extentions, arrayBuffer, resetForm) => {
    return (dispatch, getState) => {
        return dispatch({
            type: safetyActionTypes.SAFETY_PRESIGNED_URL,
            payload: API.getSafetyPresignedUrl(extentions, resetForm)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    ticket.data['attachments'] = [];

                    response.data.presigned_urls.forEach((p, i) => {
                        ticket.data.attachments.push(p.attachment_path)

                        dispatch({
                            type: safetyActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(addTrailerSafety(ticket, resetForm));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const addTrailerSafety = (ticket, resetForm) => async (dispatch, getState) => {
    return await dispatch({
        type: safetyActionTypes.ADD_TRAILER_SAFETY,
        payload: API.addTrailerSafety(ticket).then((response) => {
            if (response.data.success) {
                let ele = document.getElementById('resetTrailerSafetyImage')
                const email = cloneDeep(getState().safetyReducer.userEmail);
                if (ele) {
                    ele.click()
                }
                dispatch(reset(resetForm))
                SuccessAlert('Trailer Inspection Report Created Successfully')
                return {userEmail : email + 1}
            }
        }).catch((error) => {
            console.error(error)
        })
    });
};


//safety Action Managemnet 
export const getAllSafteyManagemntTable = (params,values) => {
    return (dispatch) => {
        dispatch({
            type: safetyActionTypes.SAFETY_UNIT_MANAGEMENT_LIST,
            payload: API.getAllSafetyManagemnetApi(params, values)
                .then((response) => {
                    if (response.data.success) {
                        //SuccessAlert("Self audit form created succesfully")
                        return {
                            safetyUnitManagemnetList: response.data.data,
                            safetyUnitManagemnetList_total_pages: response.data.totalPages,
                            safetyUnitManagemnetList_no_of_rows: response.data.limit,
                            total_safetyUnitManagemnetList_count: response.data.total,
                            safetyUnitManagemnetList_curPage: params.page - 1,
                            searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addUnitForm = (data) => {
    return (dispatch) => {
        dispatch({
            type: safetyActionTypes.ADD_SAFETY_UNIT,
            payload: API.addUnitForm(data)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Safety Unit Added Successfully')
                        history.push(routes.SAFETY_UNIT_LISTING)
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getSingleUnit = (id) => { 
    return (dispatch, getState) => {
        dispatch({
            type: safetyActionTypes.GET_SINGLE_UNIT,
            payload: API.getSingleUnit(id)
                .then((response) => {
                    if (response.data.success) {
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updateUnitForm = (data, id) => {
    return (dispatch) => {
        dispatch({
            type: safetyActionTypes.UPDATE_SAFETY_UNIT,
            payload: API.updateUnitForm(data, id)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Safety Unit Updated Successfully')
                        history.push(routes.SAFETY_UNIT_LISTING)
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const safetyUnitFormClicked = () => {
    return {
        type: safetyActionTypes.SAFETY_UNIT_FORM_CLICKED,
    };
};

export const safetyUnitListPageClicked = (data) => {
    return {
        type: safetyActionTypes.SAFETY_UNIT_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const deleteUnitForm = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: safetyActionTypes.DELETE_SAFETY_UNIT,
            payload: API.deleteUnitForm(id)
                .then((response) => {
                    if (response.data.success) {
                        let unitList = cloneDeep(getState().safetyReducer.safetyUnitManagemnetList);
                        let unitCount = cloneDeep(getState().safetyReducer.total_safetyUnitManagemnetList_count);
                        
                        debugger
                        let searchIndex = unitList.findIndex(function (t) {
                            return t.id === id;
                        });
                        unitList.splice(searchIndex, 1);
                        
                        dispatch(handleDeleteClose())
                        SuccessAlert('Safety Unit Deleted Successfully')
                        return{unitList, unitCount : unitCount - 1}
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getUnitNumber = (params) => {
    return (dispatch) => {
        dispatch({
            type: safetyActionTypes.GET_UNIT_NUMBER,
            payload: API.getUnitNumber(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                                units : response.data.data
                        }
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};