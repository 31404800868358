import * as API from '../../../../API/FormAuditAPI/auditsAPI';
import SuccessAlert from '../../../../utility/successAlert/successAlert';
import { routes } from '../../../../utility/constants/constants';
import { auditsActionTypes } from './AuditsTypes';
import history from '../../../../router/history';


export const getAllPerformanceAudit = (params, values) => {
    return (dispatch) => {
        dispatch({
            type: auditsActionTypes.GET_ALL_PERFORMANCE_AUDIT,
            payload: API.getAllPerformanceAudit(params, values)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            performanceAuditList: response.data.data,
                            performanceAuditList_total_pages: response.data.totalPages,
                            performanceAuditList_no_of_rows: response.data.limit,
                            total_performanceAuditList_count: response.data.total,
                            performanceAuditList_curPage: params.page - 1,
                            searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const performanceAuditListPageClicked = (data) => {
    return {
        type: auditsActionTypes.PERFORMANCE_AUDIT_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const performanceAuditPageClicked = () => {
    return {
        type: auditsActionTypes.PERFORMANCE_AUDIT_PAGE_CLICKED,
    };
};

export const getAllPerformanceAuditQuestions = () => {
    return (dispatch) => {
        dispatch({
            type: auditsActionTypes.GET_ALL_PERFORMANCE_AUDIT_QUESTIONS,
            payload: API.getAllPerformanceAuditQuestions()
                .then((response) => {
                    if (response.data.success) {
                        return {
                            performance_audit_questions: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};



export const getSelfAuditQuestions = () => {
    return (dispatch) => {
        dispatch({
            type: auditsActionTypes.GET_ALL_SELF_AUDIT_QUESTIONS,
            payload: API.getAllSelfAuditQuestions()
                .then((response) => {
                    if (response.data.success) {
                        
                        return {
                            self_audit_questions: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const AddToSelfAudit = (body) => {
    return (dispatch) => {
        dispatch({
            type: auditsActionTypes.ADD_SELF_AUDIT_PAGE,
            payload: API.AddSelfAuditPage(body)
                .then((response) => {
                    
                    if (response.data.success) {
                        SuccessAlert("Self audit form created succesfully")
                        history.push("/self-audit")
                        return {
                            self_audit_questions: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getSingleSelfAudit = (id) => {
    return (dispatch) => {
        dispatch({
            type: auditsActionTypes.GET_SINGLE_SELF_AUDIT_PAGE,
            payload: API.GetSingleSelfAuditPage(id)
                .then((response) => {
                    if (response.data.success) {
                        //SuccessAlert("Self audit form created succesfully")
                        return {
                            self_audit_details: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const EditSelfAudit = (body) => {
    return (dispatch) => {
        dispatch({
            type: auditsActionTypes.EDIT_SELF_AUDIT_PAGE,
            payload: API.EditSelfAuditPage(body)
                .then((response) => {
                    
                    if (response.data.success) {
                        SuccessAlert("Self audit form updated succesfully")
                        history.push("/self-audit")
                        return {
                            self_audit_questions: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};
//getAllSelfAuditForm

export const getAllSelfAuditTable = (params,values) => {
    return (dispatch) => {
        dispatch({
            type: auditsActionTypes.GET_ALL_SELF_AUDIT_PAGE,
            payload: API.getAllSelfAuditApi(params, values)
                .then((response) => {
                    if (response.data.success) {
                        
                        //SuccessAlert("Self audit form created succesfully")
                        return {
                            selfAuditList: response.data.data,
                            selfAuditList_total_pages: response.data.totalPages,
                            selfAuditList_no_of_rows: response.data.limit,
                            total_selfAuditList_count: response.data.total,
                            selfAuditList_curPage: params.page - 1,
                            searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const addPerformanceAudit = (data) => {
     
    return (dispatch, getState) => {
        
        dispatch({
            type: auditsActionTypes.ADD_PERFORMANCE_AUDIT,
            payload: API.addPerformanceAudit(data) 
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Performance Audit Created Succesfully')
                        history.push(routes.PERFORMANCE_AUDIT);   
                        return response.data; 
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getSinglePerformanceAudit = (id) => {
     
    return (dispatch, getState) => {
        dispatch({
            type: auditsActionTypes.GET_SINGLE_PERFORMANCE_AUDIT,
            payload: API.getSinglePerformanceAudit(id) 
                .then((response) => {
                    if (response.data.success) {
                        return response.data.data
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updatePerformanceAudit = (data) => {debugger
     
    return (dispatch, getState) => {
        
        dispatch({
            type: auditsActionTypes.EDIT_PERFORMANCE_AUDIT,
            payload: API.updatePerformanceAudit(data) 
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Performance Audit Updated Succesfully')
                        history.push(routes.PERFORMANCE_AUDIT);   
                        return response.data; 
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const performanceAuditEditId = (id) => {
    return {
        type: auditsActionTypes.PERFORMANCE_AUDIT_EDIT_ID,
        payload: id,
    };
};

export const selfAuditListPageClicked = (data) => {
    return {
        type: auditsActionTypes.SELF_AUDIT_LIST_PAGE_CLICKED,
        payload: data,
    };
};