import React from "react";
import { formValueSelector, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "../../UI/InputElement/InputElement";
import UnitManagementTable from "./UnitManagementTable";
import {
  inspectionType,
} from "../../../../utility/constants/constants";
import storage from "../../../../utility/storage";

let UnitManagement = (props) => {
  const { handleSubmit } = props;
  const user = storage.get('user')
  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
         
          <form onSubmit={handleSubmit}>
            <input type="hidden" value="something" />

            <div className="row" style={{ marginTop: "10px" }}>
             

              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="app-search">
                  <Input
                    dataTest="search_by_description"
                    name="unit_number"
                    className="fil_search_input safety_input_unit"
                    type="text"
                    placeholder="Unit ID"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="app-search">
                  <Input
                    dataTest="search_by_description"
                    name="year"
                    className="fil_search_input"
                    type="number"
                    placeholder="Year"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="app-search">
                  <Input
                    dataTest="search_by_description"
                    name="make"
                    className="fil_search_input"
                    type="text"
                    placeholder="Make"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="app-search">
                  <Input
                    dataTest="search_by_description"
                    name="model"
                    className="fil_search_input"
                    type="text"
                    placeholder="Model"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="app-search">
                  <Input
                    dataTest="search_by_description"
                    name="reg_state"
                    className="fil_search_input"
                    type="text"
                    placeholder="Reg State"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="app-search">
                  <Input
                    dataTest="search_by_description"
                    name="color"
                    className="fil_search_input"
                    type="text"
                    placeholder="Color"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="app-search">
                  <Input
                    dataTest="search_by_description"
                    name="location"
                    className="fil_search_input"
                    type="text"
                    placeholder="Location Of Vehicle"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div
                className="col-xl-2 col-lg-4 col-md-6 col-sm-6"
                style={{ maxWidth: "17%" }}
              >
                <Input
                  name="type"
                  placeholder="Type"
                  type="inputSelect"
                  options={inspectionType}
                />
              </div>

              {/* end */}

              <div className="col-xl-8 col-lg-6 col-sm-3 col-12 text-left">
                <button
                  data-test="filter_go_btn"
                  style={{ minWidth: "90px" }}
                  className={"btn cst_btn btn_danger mr-2  account_btn"}
                  id="submit"
                  type="submit"
                >
                  {"Go"}{" "}
                </button>
                <button
                  data-test="filter_reset_btn"
                  style={{ minWidth: "90px" }}
                  onClick={props.resetClicked}
                  className={"btn cst_btn btn-outline-secondary account_btn"}
                  id="reset"
                  type="reset"
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          </form>
        </div>

                <section className="account_sec inspection_tab_content appcontent_Inner">
                  {user?.user_permissions?.unit_access ?  <div className='row'>
                        <div className="col-xl-12 col-lg-6 col-sm-3 col-12 text-right quotes_btn_wrap mb-3">
                        <a onClick={props.newTicketClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn" ><i className="fa fa-plus icn_plus"></i> Add Unit</a>
                        </div>
                    </div> : null} 
                    {/* <CSVLink filename="Accounts.csv" className="csv_btn btn_danger " data={convertAccountsDataToCsvType(props.accountsList)} >Export Csv</CSVLink><br /><br /><br /> */}
                    <div className="quotes_table_content accounts_table_contnet table-responsive">

                        <div className="table quotes_table user_react_table">
                            <UnitManagementTable
                                {...props}
                            />
                        </div>

                    </div>
                    {/* <div className="clearfix"></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_danger cst_btn">Save</button>
                    <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                </div> */}
        </section>
      </main>
      <Dialog
        open={props.openDeleteModel}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ fontSize: "13", color: "#06080f" }}
        >
          <h3 style={{ color: "#ab385e" }} data-test="modal_delete_title">
            Are you sure?
          </h3>
        </DialogTitle>

        <DialogActions>
          <button
            data-test="modal_delete"
            style={{ minHeight: "36px", minWidth: "80px" }}
            onClick={() => props.deleteUnitForm(props.deleteObject.id)}
            disabled={props.isLoading}
            className={
              props.isLoading
                ? "btn cst_btn btn_danger mr-2 btn-wait"
                : "btn cst_btn btn_danger mr-2"
            }
            id="delete"
            type="delete"
          >
            {props.isLoading ? "" : "Delete"}
          </button>
          <button
            data-test="modal_cancel"
            style={{ minHeight: "36px", minWidth: "80px" }}
            onClick={props.handleDeleteClose}
            className={"btn cst_btn btn-outline-secondary"}
            id="cancel"
            type="cancel"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  let unit_number =
    state.safetyReducer.safetyManagement_searchValues &&
    state.safetyReducer.safetyManagement_searchValues.unit_number
      ? state.safetyReducer.safetyManagement_searchValues.unit_number
      : "";
  let year =
    state.auditsReducer.selfAuditList_searchValues &&
    state.auditsReducer.selfAuditList_searchValues.year
      ? state.auditsReducer.selfAuditList_searchValues.year
      : "";
  let make =
    state.auditsReducer.selfAuditList_searchValues &&
    state.auditsReducer.selfAuditList_searchValues.make
      ? state.auditsReducer.selfAuditList_searchValues.make
      : "";
  let model =
    state.auditsReducer.selfAuditList_searchValues &&
    state.auditsReducer.selfAuditList_searchValues.model
      ? state.auditsReducer.selfAuditList_searchValues.model
      : "";
  let reg_state =
    state.auditsReducer.selfAuditList_searchValues &&
    state.auditsReducer.selfAuditList_searchValues.reg_state
      ? state.auditsReducer.selfAuditList_searchValues.reg_state
      : "";

  let color =
    state.auditsReducer.selfAuditList_searchValues &&
    state.auditsReducer.selfAuditList_searchValues.color
      ? state.auditsReducer.selfAuditList_searchValues.color
      : "";
  let location =
    state.auditsReducer.selfAuditList_searchValues &&
    state.auditsReducer.selfAuditList_searchValues.location
      ? state.auditsReducer.selfAuditList_searchValues.location
      : "";
  let type =
    state.auditsReducer.selfAuditList_searchValues &&
    state.auditsReducer.selfAuditList_searchValues.type
      ? state.auditsReducer.selfAuditList_searchValues.type
      : "";
  return {
    initialValues: {
      unit_number,
      year,
      make,
      model,
      reg_state,
      color,
      location,
      type,
    },
    formStates: getFormValues("searchSafetyUnitManagement")(state),
  };
};

UnitManagement = reduxForm({
  form: "searchSafetyUnitManagement",
  // validate,
  enableReinitialize: true,
})(UnitManagement);
const selector = formValueSelector("searchSafetyUnitManagement");
UnitManagement = connect((state) => {
  const {
    unit_number,
    year,
    make,
    model,
    reg_state,
    color,
    location,
    type,
  } = selector(
    state,
    "unit_number",
    "year",
    "make",
    "model",
    "reg_state",
    "color",
    "location",
    "type"
  );
  const selectedValues = {
    unit_number,
    year,
    make,
    model,
    reg_state,
    color,
    location,
    type,
  };
  return {
    selectedValues,
  };
})(UnitManagement);
UnitManagement = connect(mapStateToProps)(UnitManagement);

export default UnitManagement;
