import { safetyActionTypes } from "../../actions/SafetyActions/SafetyActionType";


export const initialState = {
    safetyToggle: false,
    isLoading: false,

    safetyUnitManagemnetList:[],
    isSafetyUnitManagementList: false,
    safetyUnitManagemnetList_total_pages:1,
    safetyUnitManagemnetList_no_of_rows:10,
    safetyUnitManagemnetList_curPage:0,
    total_safetyUnitManagemnetList_count:"",
    safetyManagement_searchValues:{},

    singleUnitData : {},
    isEditSafetyUnit: false, 
    isUpdateSafetyUnit: false,
    units:[],

    userEmail : 0,
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const safetyReducer = (state = initialState, action) => {
    switch (action.type) {

        case safetyActionTypes.SAFETY_CLICKED:
            return updateObject(state, { safetyToggle: !state.safetyToggle });

        case safetyActionTypes.ADD_VEHICLE_SAFETY_PENDING:
            return updateObject(state, { isLoading: true })
        case safetyActionTypes.ADD_VEHICLE_SAFETY_FULFILLED:
            return updateObject(state, { isLoading: false,  userEmail : action.payload.userEmail })

        case safetyActionTypes.SAFETY_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })

        case safetyActionTypes.UPLOAD_IMAGE_TO_S3_PENDING:
            return updateObject(state, { isLoading: true })
        case safetyActionTypes.UPLOAD_IMAGE_TO_S3_FULFILLED:
            return updateObject(state, { isLoading: true })

        case safetyActionTypes.ADD_GOLF_CART_SAFETY_PENDING:
            return updateObject(state, { isLoading: true })
        case safetyActionTypes.ADD_GOLF_CART_SAFETY_FULFILLED:
            return updateObject(state, { isLoading: false,  userEmail : action.payload.userEmail })

        case safetyActionTypes.SAFETY_UNIT_MANAGEMENT_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isSafetyUnitManagementList: true,
                safetyUnitManagemnetList: [],
            });

            case safetyActionTypes.SAFETY_UNIT_MANAGEMENT_LIST_FULFILLED:
                return updateObject(state, {
                    isLoading: false,
                    isSafetyUnitManagementList: false,
                    safetyUnitManagemnetList: action.payload.safetyUnitManagemnetList,
                    safetyUnitManagemnetList_total_pages: action.payload.safetyUnitManagemnetList_total_pages,
                    safetyUnitManagemnetList_no_of_rows: action.payload.safetyUnitManagemnetList_no_of_rows,
                    safetyUnitManagemnetList_curPage: action.payload.safetyUnitManagemnetList_curPage,
                    total_safetyUnitManagemnetList_count: action.payload.total_safetyUnitManagemnetList_count,
                    safetyManagement_searchValues: action.payload.searchValues,
                });

            case safetyActionTypes.ADD_SAFETY_UNIT_PENDING:
            return updateObject(state, { isLoading: true })
            case safetyActionTypes.ADD_SAFETY_UNIT_FULFILLED:
            return updateObject(state, { isLoading: false })

            case safetyActionTypes.GET_SINGLE_UNIT_PENDING:
            return updateObject(state, { isLoading: true, singleUnitData : {},  isEditSafetyUnit: false, isUpdateSafetyUnit: true, })
            case safetyActionTypes.GET_SINGLE_UNIT_FULFILLED:
            return updateObject(state, { isLoading: false, singleUnitData : action.payload.data, isEditSafetyUnit: true, isUpdateSafetyUnit: false, })

            case safetyActionTypes.UPDATE_SAFETY_UNIT_PENDING:
                return updateObject(state, { isLoading: true })
            case safetyActionTypes.UPDATE_SAFETY_UNIT_FULFILLED:
                return updateObject(state, { isLoading: false,   isEditSafetyUnit: false, isUpdateSafetyUnit: false, })

            case safetyActionTypes.SAFETY_UNIT_FORM_CLICKED:
                    return updateObject(state, {singleUnitData : {},  isEditSafetyUnit: false, isUpdateSafetyUnit: false });

            case safetyActionTypes.SAFETY_UNIT_LIST_PAGE_CLICKED:
                        return updateObject(state, {safetyUnitManagemnetList_curPage : action.payload });

            case safetyActionTypes.DELETE_SAFETY_UNIT_PENDING:
            return updateObject(state, { isLoading: true })
            case safetyActionTypes.DELETE_SAFETY_UNIT_FULFILLED:
            return updateObject(state, { isLoading: false, safetyUnitManagemnetList: action.payload.unitList, total_safetyUnitManagemnetList_count : action.payload.unitCount })    

            case safetyActionTypes.GET_UNIT_NUMBER_PENDING:
            return updateObject(state, { isLoading: true })
            case safetyActionTypes.GET_UNIT_NUMBER_FULFILLED:
            return updateObject(state, { isLoading: false, units: action.payload.units })

            case safetyActionTypes.ADD_TRAILER_SAFETY_PENDING:
            return updateObject(state, { isLoading: true })
        case safetyActionTypes.ADD_TRAILER_SAFETY_FULFILLED:
            return updateObject(state, { isLoading: false, userEmail : action.payload.userEmail })
            
                
        default:
            return state;
    }
};