import axios, { API_VERSION, timeTrackerBaseUrl } from '../../config';
const pureAxios = require('axios');

const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export const addCutomMatrixApi = (body) => {
  return timeTrackerBaseUrl.post(API_VERSION + `matrix/`, body, headers);  
};

export const getSafetyPresignedUrl = (extentions, resetForm) => {
  const data = { ext: extentions };
  return timeTrackerBaseUrl.patch(
      API_VERSION + `config/presignUrl?name=${resetForm}`,
      {data},
      headers
  );
};

export const addVehicleSafety = (ticket) => {
  return timeTrackerBaseUrl.post(API_VERSION + `safety/vechile_inspection`, ticket, headers);
};

export const uploadImageToS3 = (url, arrayBuffer) => {
  return pureAxios.put(url, arrayBuffer);
};

export const  addGolfCartSafety = (ticket) => {
  return timeTrackerBaseUrl.post(API_VERSION + `safety/golf_cart_inspection`, ticket, headers);
};

export const  addTrailerSafety = (ticket) => {
  return timeTrackerBaseUrl.post(API_VERSION + `safety/trailer_inspection`, ticket, headers);
};

//safety UNIT-MANAGEMENT
export const getAllSafetyManagemnetApi = (params) => {
  return timeTrackerBaseUrl.get(API_VERSION + `safety/units`,{params} , headers);
};

export const  addUnitForm = (data) => {
  return timeTrackerBaseUrl.post(API_VERSION + `safety/units`, data, headers);
};

export const getSingleUnit = (id) => {
  return timeTrackerBaseUrl.get(API_VERSION + `safety/units/${id}`, headers);
}

export const  updateUnitForm = (data, id) => {
  return timeTrackerBaseUrl.put(API_VERSION + `safety/units/${id}`, data, headers);
};

export const  deleteUnitForm = (id) => {
  return timeTrackerBaseUrl.delete(API_VERSION + `safety/units/${id}`, headers);
};

export const getUnitNumber = (params) => {
  return timeTrackerBaseUrl.get(API_VERSION + `safety/units/list`,{params} , headers);
};