import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { convertedQuarterlyDate, makeFirstLetterCapital, showModeToggleButton } from '../../utility/utility';
import * as actions from '../../redux/actions/index';
import { TEAM_MANAGEMENT_ROUTES, quarterCheckDates, routes, teamChecklistAccess, userRoles } from '../../utility/constants/constants';
import UpdateProfile from '../../adminOrSuperAdminOrRequestor/container/UpdateProfile/UpdateProfileContainer';
import ModeModal from '../../adminOrSuperAdminOrRequestor/component/updateProfile/modeModal';
import $ from 'jquery';
import SuccessAlert from '../../utility/successAlert/successAlert';
import { showConfirmAlert } from '../../utility/successAlert/ConfirmAlert';
import storage from '../../utility/storage';
import Collapse from 'react-bootstrap/Collapse';
import { useLocation } from 'react-router-dom'

const queryString = require('query-string');

const HeaderNames = (props) => {
    const location = useLocation();
    const account_info = useSelector(state => state.adminOrSuperAdminReducer.currentAccount);
    const scheduleTitle = storage.get('scheduleTitle')
    const scheduleTickekId = storage.get('scheduleTickekId')
    const scheduleTaskTitle = storage.get('scheduleTaskTitle')
    const scheduleTaskTickekId = storage.get('scheduleTaskTickekId')
    const assetName = storage.get('assetTitle')
    const backInspectionClicked = () => {
        // storage.remove('IdsToDelete');
        // storage.remove('performInspectionData');
        props.props.history.goBack();
    }
    const shareableId = props.props.shareableId ?  queryString.stringify({sharaeble : props.props.shareableId }): null


    if (props.props.location.pathname === routes.DASHBORD || props.props.location.pathname === routes.HOME) {
        return <h1 className="app_title" data-test="dashboard_text_header"> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Dashboard </h1>
    } else if (props.props.location.pathname === routes.ACCOUNTS) {
        return <h1 className="app_title" data-test="jobs_text_header"> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Jobs  ({props.props.totalAccounts}) </h1>
    } else if (props.props.location.pathname === routes.NEW_ACCOUNTS) {
        return <h1 className="app_title"><a href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Job </h1>;
    } else if (props.props.location.pathname.includes(`account_users`)) {
        let tab = '';
        let newarea = '';
        let schedules = '';
        let isInspectionTicketHistoryTitle = true;
        let reDirectAccountUsers = storage.get('accountUsersRedirect');
        if (props.props.location.search.includes("job_area=true")) {
            tab = 'areas';
        } else if (props.props.location.search.includes("job_holiday=true")) {
            tab = 'holidays'
        } else if (props.props.location.search.includes("job_payroll_holiday=true")) {
            tab = 'payroll_holliday'
        }
        else if (props.props.location.search.includes("job_hoursType=true")) {
            tab = 'hoursType'
        }
        else if (props.props.location.search.includes("job_users=true")) {
            tab = 'users'
        } else if (props.props.location.search.includes("job_schedules=true")) {
            tab = 'schedules'
        } else if (props.props.location.search.includes("job_logbook=true")) {
            tab = 'logbook'
        } else if (props.props.location.pathname.includes("view_logbookarea")) {
            tab = 'previewLogbook'
        } else if (props.props.location.pathname.includes("inspection-schedule-history")) {
            tab = 'scheduleHistory'
            isInspectionTicketHistoryTitle = !isNaN(window.location.href.split('/')[window.location.href.split('/').indexOf('accounts') + 1])
        } else if (props.props.location.pathname.includes("ticket-schedule-history")) {
            tab = 'scheduleTicketHistory'
            isInspectionTicketHistoryTitle = !isNaN(window.location.href.split('/')[window.location.href.split('/').indexOf('accounts') + 1])
        } else {
            tab = 'users'
        }
        if (props.props.location.pathname.includes('newarea') || (props.props.location.pathname.includes('area') && props.props.location.pathname.includes('edit'))) {
            newarea = true;
            tab = 'areas';
        } else {
            newarea = false;
        }
        if (props.props.location.pathname.includes('schedule-time') || (props.props.location.pathname.includes('create-new-schedule')) || (props.props.location.pathname.includes('create-new-ticket-schedule'))) {
            schedules = true;
            tab = 'schedules';
            isInspectionTicketHistoryTitle = !isNaN(window.location.href.split('/')[window.location.href.split('/').indexOf('accounts') + 1])
        } else {
            schedules = false;
        }
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => {
            if (window.location.href.includes('#')) {
                (!(tab === 'schedules' || tab === 'scheduleHistory' || tab === 'scheduleTicketHistory') && reDirectAccountUsers && reDirectAccountUsers.length > 0 ? props.props.history.push(reDirectAccountUsers) : (tab === 'users' || tab === 'areas' || tab === 'logbook' || tab === 'holidays') ? props.props.history.push(routes.ACCOUNTS) : props.props.history.goBack());
                setTimeout(() => {
                    (!(tab === 'schedules' || tab === 'scheduleHistory' || tab === 'scheduleTicketHistory') && reDirectAccountUsers && reDirectAccountUsers.length > 0 ? props.props.history.push(reDirectAccountUsers) : (tab === 'users' || tab === 'areas' || tab === 'logbook' || tab === 'holidays') ? props.props.history.push(routes.ACCOUNTS) : props.props.history.goBack());
                }, 100);
            } else {
                (reDirectAccountUsers && reDirectAccountUsers.length > 0 ? props.props.history.push(reDirectAccountUsers) : (tab === 'users' || tab === 'areas' || tab === 'logbook' || tab === 'holidays') ? props.props.history.push(routes.ACCOUNTS) : props.props.history.goBack());
            }
            storage.remove('accountUsersRedirect');
        }} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>  {props.props.account && props.props.account.user && props.props.account.user ? props.props.account.user.name : props.props && props.props.account && isInspectionTicketHistoryTitle && props.props.account.name} {(tab === 'areas' && !newarea) ? `(${props.props.totalAccountAreas})` : null}{(tab === 'logbook') ? `(${props.props.totalLogbookAreas})` : null} {tab === 'holidays' ? `(${props.props.totalAccountHolidays})` : null} {tab === 'users' ? `(${props.props.totalAccountUsers})` : null} {tab === 'previewLogbook' ? `Preview Logbook Area(${props.props.totalLogbookAreas})` : null} {tab === 'scheduleHistory' && !(isInspectionTicketHistoryTitle) ? 'History' : null} {tab === 'scheduleTicketHistory' && !(isInspectionTicketHistoryTitle) ? 'History' : null} {tab === 'payroll_holliday' ? `(${props.props.totalPayrollHolliday})` : null}  {tab === 'hoursType' ? `(${props.props.totalHoursType})` : null} </h1>;
    } else if (props.props.location.pathname === routes.COMPANIES) {
        return <h1 className="app_title"> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Customers({props.props.totalCompanies}) </h1>;
    } else if (props.props.location.pathname === routes.API_ACCESS) {
        return <h1 className="app_title"> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> API Access({props.props.total_company_access}) </h1>;
    } else if (props.props.location.pathname === routes.NEW_COMPANY) {
        return <h1 className="app_title"><a href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>Customer </h1>;
    } else if (props.props.location.pathname.includes(`view_log`)) {
        return <h1 className="app_title"><a href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>Back </h1>;
    } else if (props.props.location.pathname.includes(`customer_users`)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push(routes.COMPANIES)} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {props.props.companyName} </h1>;
    } else if (props.props.location.pathname === routes.NEW_USER || props.props.location.pathname.includes(`new_account_user`) || props.props.location.pathname.includes(`new_company_user`)) {
        let newUserFormRedirectRoute = '';
        if (props.props.location && props.props.location.state) {
            if (props.props.location.state.storedRoute) {
                newUserFormRedirectRoute = props.props.location.state.storedRoute;
            }
        }
        return <h1 className="app_title"> <a href="javascript:void(0)" onClick={() => {
            props.props.history.push(newUserFormRedirectRoute && newUserFormRedirectRoute.length > 0 ? newUserFormRedirectRoute : props.props.location.pathname === routes.NEW_USER ? props.props.history.goBack() : routes.ACCOUNTS)
        }} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> User </h1>
    } else if (props.props.location.pathname.includes(`previewUser`)) {
        return <h1 className="app_title"> <a href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Preview User </h1>
    } else if (props.props.location.pathname === routes.USER) {
        return <h1 data-test="users_header_title" className="app_title"> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Users  ({props.props.totalUsers}) </h1>
    } else if (props.props.location.pathname === routes.QUOTES) {
        return <h1 className="app_title" data-test="quotes_header_title"> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Quotes({props.props.totalQuotes}) </h1>;
    }
    else if (props.props.location.pathname === routes.LOGBOOK) {
        return <h1 className="app_title" data-test="quotes_header_title"> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Pegassure Logbook Logs({props.props.totalLogbookLogs}) </h1>;
    } else if ((props.props.location.pathname === routes.NEW_QUOTES || props.props.location.pathname === routes.MANUALL_QUOTE)) {
        return <h1 className="app_title"><a href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Quotes </h1>;
    } else if ((props.props.location.pathname === routes.VIEW_FILTERED_QUOTES)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push(routes.DASHBORD)} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Dashboard/Quotes  ({props.props.dashboardQuoteCount}) </h1 >;
    } else if ((props.props.location.pathname === routes.VIEW_FILTERED_INSPECTIONS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push(routes.DASHBORD)} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Dashboard/Inspections  ({props.props.totalInspections}) </h1 >;
    } else if ((props.props.location.pathname === routes.VIEW_FILTERED_TICKETS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push(routes.DASHBORD)} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Dashboard/Tickets  ({props.props.dashboardTicketCount}) </h1 >;
    } else if ((props.props.location.pathname === routes.INSPECTIONS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> All Inspections  ({props.props.totalInspections}) </h1 >;
    } else if ((props.props.location.pathname === routes.MY_INSPECTIONS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> My Inspections  ({props.props.totalInspections}) </h1 >;
    } else if ((props.props.location.pathname === routes.PENDING_INSPECTIONS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Pending Inspections  ({props.props.totalInspections}) </h1 >;
    } else if ((props.props.location.pathname === routes.INSPECTION_FORMS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Inspection Forms  ({props.props.totalInspectionForms}) </h1 >;
    } else if ((props.props.location.pathname.includes(`performInspection`))) {
        return <h1 data-test="perform_inspection_title" className="app_title"><a href="javascript:void(0)" onClick={() => {
            setTimeout(() => {
                window.location.reload();
            }, 100);
            props.props.history.goBack();
        }} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Perform Inspection </h1 >;
    } else if ((props.props.location.pathname.includes(`previewInspection`))) {
        return <h1 className="app_title"><a href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Preview Inspection </h1 >;
    } else if ((props.props.location.pathname.includes(`editInspection`))) {
        return <h1 className="app_title"><a href="javascript:void(0)" onClick={backInspectionClicked} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Edit Inspection </h1 >;
    } else if ((props.props.location.pathname === routes.REPORTS)) {
        return <h1 className="app_title" data-test="reports_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Reports </h1 >;
    } else if ((props.props.location.pathname === routes.TICKETS)) {
        return <h1 className="app_title" data-test="ticket_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>All Tickets({props.props.totalTickets}) </h1 >;
    } else if ((props.props.location.pathname === routes.PRIORITY_ESCALATION_TICKETS)) {
        return <h1 className="app_title" data-test="ticket_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>PegAssure Tickets({props.props.prior_totalTickets}) </h1 >;
    } else if ((props.props.location.pathname === routes.MY_TICKETS)) {
        return <h1 className="app_title" data-test="ticket_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>My Tickets  ({props.props.totalTickets}) </h1 >;
    } else if ((props.props.location.pathname === routes.SCHEDULED_TICKETS)) {
        return <h1 className="app_title" data-test="ticket_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>Scheduled Tickets  ({props.props.scheduleTotalTickets}) </h1 >;
    } else if ((props.props.location.pathname.includes(`/schedules/ticket/`))) {
        let scheduleTicketStoredRoute = '';
        if (props.props.location && props.props.location.state) {
            if (props.props.location.state.storedRoute) {
                scheduleTicketStoredRoute = props.props.location.state.storedRoute;
            }
        }
        return <h1 className="app_title" data-test="ticket_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => {
            props.props.history.push(scheduleTicketStoredRoute && scheduleTicketStoredRoute.length > 0 ? scheduleTicketStoredRoute : routes.SCHEDULED_TICKETS)
        }} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>{makeFirstLetterCapital(props.props.scheduleTitle ? props.props.scheduleTitle : scheduleTitle)}  ({props.props.previewScheduleTotalTickets}) </h1 >;
    } else if ((props.props.location.pathname === routes.NEW_TICKET)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Tickets </h1 >;
    } else if ((props.props.location.pathname === `/tickets/${props.props.ticket.id}`)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Tickets </h1 >;
    } else if ((props.props.location.pathname.includes(`view_ticket`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push({pathname : routes.TICKETS, search : shareableId ? '?'+ shareableId : null})} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Preview Ticket </h1 >;
    } else if ((props.props.location.pathname.includes(`priority`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push(props.props.location.pathname.includes(`priority`) ? routes.PRIORITY_ESCALATION_TICKETS : routes.TICKETS)} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> PegAssure Preview Ticket </h1 >;
    } else if ((props.props.location.pathname === routes.RATINGS)) {
        return <h1 className="app_title" data-test="rating_head_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Ratings  ({props.props.totalRatings}) </h1 >;
    } else if ((props.props.location.pathname === routes.CATEGORIES)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Categories  ({props.props.totalCategories})  </h1 >;
    } else if ((props.props.location.pathname === routes.SETTINGS_PREFERENCES || props.props.location.pathname.includes(`preferences`))) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Email Summary Notifications </h1 >;
    } else if ((props.props.location.pathname === routes.INSIGHTS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> PegAssure </h1 >;
    } else if ((props.props.location.pathname === routes.FRONTLINE_LIST)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2" data-test="front_line_title"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Frontline Users   ({props.props.totalfrontline}) </h1 >;
    } else if ((props.props.location.pathname === routes.PREVIEW_CHI)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2" data-test="chipreview_title"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>CHI Surveys</h1 >;
    } else if ((props.props.location.pathname === routes.CRITERIA)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Criteria  ({props.props.totalCriteries})  </h1 >;
    } else if ((props.props.location.pathname === routes.CHI_SERVYS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2" data-test="chipreview_title"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>CHI Surveys</h1 >;
    } else if ((props.props.location.pathname.includes(`kpidashboard`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>{props.props.companyName}</h1 >;
    } else if (props.props.location.pathname === routes.SCHEDULE_TAB) {
        return <h1 className="app_title" data-test="quotes_header_title"> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Schedules </h1>;
    } else if ((props.props.location.pathname.includes(`create-new-schedule`))) {
        return <h1 className="app_title"><a href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Create Schedule</h1 >;
    } else if ((props.props.location.pathname === routes.SCAN_LOGBOOK_QR)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> BACK </h1 >;
    } else if ((props.props.location.pathname === routes.LOGBOOK_THANKYOU)) {
        let reDirect = storage.get('logScanHistory');
        if (reDirect && reDirect.includes("view_logbookarea") || reDirect && reDirect.includes("logbook")) {
            return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push(reDirect)} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> BACK </h1 >;
        } else {
            return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push(routes.DASHBORD)} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> BACK </h1 >;
        }
    } else if ((props.props.location.pathname.includes(`feedbacks`))) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2" data-test="chipreview_title"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>PegAssure Feedbacks ({props.props.totalFeedbacks}) </h1 >;
    } else if ((props.props.location.pathname === routes.USER_PROFILE)) {
        return <h1 className="app_title" data-test="ticket_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>User Profile</h1 >;
    } else if ((props.props.location.pathname.includes(`preview-ticket`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => {
            let storedRoute = ''
            if (props.props.history.location && props.props.history.location.state) {
                if (props.props.history.location.state.storedRoute) {
                    storedRoute = props.props.history.location.state.storedRoute;
                }
            }
            if(scheduleTickekId || props.props.scheduleTicketId){
                props.props.history.push({ pathname: `/schedules/ticket/${props.props.scheduleTicketId ? props.props.scheduleTicketId : scheduleTickekId}`, state: { storedRoute: storedRoute && storedRoute.length > 0 ? storedRoute : null } });
            }else{
                props.props.history.goBack()
            }
        }} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Preview Ticket </h1 >;
    } else if ((props.props.location.pathname === routes.BOX)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> List of Boxes  ({props.props.box_total_count}) </h1 >;
    } else if ((props.props.location.pathname === routes.AUDIT_LOGS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Pickups </h1 >;
    } else if ((props.props.location.pathname === routes.PICKING_TICKETS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Picking Tickets ({props.props.pickingTicket_total_count})</h1 >;
    } else if ((props.props.location.pathname === routes.EMERGENCY_PICKING_TICKETS)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Emergency Picking Tickets ({props.props.pickingTicket_total_count})</h1 >;
    } else if ((props.props.location.pathname === routes.ASSETS_LIST)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> List of Assets ({props.props.assests_total_count}) </h1 >;
    } else if ((TEAM_MANAGEMENT_ROUTES.includes(props.props.location.pathname))) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Team Management </h1 >;
    }
    else if ((props.props.location.pathname === routes.ASSETS_STOCK)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Assets in Stock </h1 >;
    } else if ((props.props.location.pathname === routes.ADD_BOX)) {
        return <h1 className="app_title"><a href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Box </h1 >;
    } else if ((props.props.location.pathname === routes.INVENTORY_MAIN_LIST)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> List of Inventories  ({props.props.total_inventoryItemsList_count}) </h1 >;
    } else if ((props.props.location.pathname.includes(`preview-inventory-item`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Inventory Stock Details </h1 >;
    } else if ((props.props.location.pathname.includes(`pickups-tickets`))) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Pickups Tickets ({props.props.pickupsTicket_total_count}) </h1 >;
    } else if ((props.props.location.pathname.includes(`audit-logs`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Back </h1 >;
    } else if ((props.props.location.pathname.includes(`view-asset`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Back </h1 >;
    } else if ((props.props.location.pathname === routes.LOCATION)) {
        return <h1 className="app_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Location </h1 >;
    } else if ((props.props.location.pathname.includes(`unavailable-items`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Unavailable Items </h1 >;
    } else if ((props.props.location.pathname.includes(`assets/asset-stock`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`${makeFirstLetterCapital(assetName)}(${props.props.assestStock_total_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.SKILLSET)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Skill Sets (${props.props.total_skillSet_list})`} </h1 >;
    }else if ((props.props.location.pathname === routes.HOURSTYPE)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Hours type (${props.props.total_isHourType_list})`} </h1 >;
    } else if ((props.props.location.pathname === routes.DEPARTMENT)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Departments (${props.props.total_department_list})`} </h1 >;
    } else if ((props.props.location.pathname === routes.LEAVE_LIST)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Leave Requests (${props.props.total_leavesList_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.SCHEDULED_TASKS)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Scheduled Tasks (${props.props.total_scheduledTaskList_count})`} </h1 >;
    } else if ((props.props.location.pathname.includes(`sub-ticket`))) {
        let scheduleTicketStoredRoute = '';
        if (props.props.location && props.props.location.state) {
            if (props.props.location.state.storedRoute) {
                scheduleTicketStoredRoute = props.props.location.state.storedRoute;
            }
        }
        return <h1 className="app_title" data-test="ticket_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => {
            props.props.history.push(scheduleTicketStoredRoute && scheduleTicketStoredRoute.length > 0 ? scheduleTicketStoredRoute : routes.SCHEDULED_TASKS)
        }} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>{makeFirstLetterCapital(props.props.scheduleTaskTitle ? props.props.scheduleTaskTitle : scheduleTaskTitle)}  ({props.props.total_subScheduledTaskList_count}) </h1 >;
    } else if ((props.props.location.pathname.includes(`preview-scheduled-task`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Preview Ticket </h1 >;
    } else if ((props.props.location.pathname.includes(`leaves/preview`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Back </h1 >;
    } else if ((props.props.location.pathname.includes(`calendar/availability`))) {

        if (props && props.props && props.props.user && props.props.user.role && props.props.user.role === userRoles.FRONTLINE) {
            return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> My Availability Calendar </h1 >;
        } else {
            return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push(routes.FRONTLINE_LIST)} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {props.props.frontline_active_user_name} Availability Calendar </h1 >;
        }

    } else if ((props.props.location.pathname === routes.SHIFT_SCHEDULE)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Shifts (${props.props.total_shiftScheduleList_count})`} </h1 >;
    } else if ((props.props.location.pathname.includes(`shift-schedule/single-shift-schedule`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => {props.props.history.goBack(); props.props.resetShiftScheduleDetailFilters()}} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`View Shift Details (${props.props.total_shiftScheduleDetailList_count})`} </h1 >;
    } else if ((props.props.location.pathname.includes(`calendar/shift`))) {

        if (props && props.props && props.props.user && props.props.user.role && props.props.user.role === userRoles.FRONTLINE) {
            return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> My Shift Calendar </h1 >;
        } else {
            return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.push(routes.FRONTLINE_LIST)} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>{props.props.frontline_active_user_name}  Shift Calendar </h1 >;
        }
    } else if ((props.props.location.pathname.includes(`preview-shift-schedule`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Back`} </h1 >;
    } else if ((props.props.location.pathname === routes.SHIFT)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`My Shifts (${props.props.total_shiftList_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.SWAP_LIST)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`${props.props.user && props.props.user.role && props.props.user.role === userRoles.FRONTLINE ? 'My Swap Requests' : 'Swap Requests'} (${props.props.total_swapList_count})`} </h1 >;
    } else if ((props.props.location.pathname.includes(`swap-request/details`)) || (props.props.location.pathname.includes(`swapped/aspects`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => { props.props.history.push(routes.SWAP_LIST); props.props.handleCancelClickAction(); }} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Back </h1 >;
    } else if ((props.props.location.pathname === routes.OVERRIDE_AVAILABILITY_REQUEST_LIST)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`${props.props.user && props.props.user.role && props.props.user.role === userRoles.FRONTLINE ? 'My Override Availability Requests' : 'Override Availability Requests'} (${props.props.total_overrideAvailabilityRequestList_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.AVAILABILITY_LIST)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Availability List (${props.props.total_availabilityList_count})`} </h1 >;
    } else if ((props.props.location.pathname.includes(`availability-list/single-availability-list`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`View Availability Details (${props.props.total_shiftScheduleDetailList_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.TEAM_CHECKLIST_LOG)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Team Checklist Log (${props.props.total_checklistLog_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.TEAM_CHECKLIST)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Team Checklist (${props.props.total_checklist_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.TEAM_CHECKLIST_MANAGER_SUMMARY)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Team Checklist Manager Summary (${props.props.total_checklistManager_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Team Checklist Supervisor Summary (${props.props.total_checklistSupervisor_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.TRAINING_LOG)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Training log (${props.props.total_trainingLog_count})`} </h1 >;
    } else if ((props.props.location.pathname === routes.RESTROOM_PORTERING)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Restroom Portering Frequency Log : Men / Women (${props.props.total_restroomPortering_count})`} </h1 >;
    }
    else if ((props.props.location.pathname === routes.DEEP_CLEAN_CHART)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Deep Clean Chart (${props.props.deep_clean_count})`} </h1 >;
    }
    else if ((props.props.location.pathname === routes.REQUSET_FORM)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Request Form (${props.props.total_requestform_count})`} </h1 >;
    }
    else if ((props.props.location.pathname === routes.JOB_CORE_MAP_CARD)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Job and Core Map Cards (${props.props.total_jobcard_count})`} </h1 >;
    }
    else if ((props.props.location.pathname === routes.BUILDING_PROFILE)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Building Profile (${props.props.total_buildingProfile_count})`} </h1 >;
    }
    else if ((props.props.location.pathname === routes.DAILY_KITTING)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Daily Kitting : Safety Log (${props.props.total_dailyKitting_count})`} </h1 >;
    }
    else if ((props.props.location.pathname === routes.MONTHLY_USAGE_REPORT)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Monthly Usage Reports </h1 >;
    }
    else if ((props.props.location.pathname === routes.MONTHLY_COST_SUMMARY_ANALYSIS)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Monthly Cost Summary And Analysis </h1 >;
    }
    else if ((props.props.location.pathname === routes.NEW_PERFORMANCE_AUDIT)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Performance Audit </h1 >;
    }
    else if ((props.props.location.pathname === routes.PROJECT_MODULE)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Deep Clean Schedule Tickets  ({props.props.scheduleTotalTickets})  </h1 >;
    }
    else if ((props.props.location.pathname.includes(`/project-module/ticket/`))) {
        let scheduleTicketStoredRoute = '';
        if (props.props.location && props.props.location.state) {
            if (props.props.location.state.storedRoute) {
                scheduleTicketStoredRoute = props.props.location.state.storedRoute;
            }
        }
        return <h1 className="app_title" data-test="ticket_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => {
            props.props.history.push(scheduleTicketStoredRoute && scheduleTicketStoredRoute.length > 0 ? scheduleTicketStoredRoute : routes.PROJECT_MODULE)
        }} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>{makeFirstLetterCapital(props.props.scheduleTitle ? props.props.scheduleTitle : scheduleTitle)}  ({props.props.previewScheduleTotalTickets}) Deep Clean </h1 >;
    }
    else if ((props.props.location.pathname.includes(`/project-module/tickets/preview/`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => {
            let storedRoute = ''
            if (props.props.history.location && props.props.history.location.state) {
                if (props.props.history.location.state.storedRoute) {
                    storedRoute = props.props.history.location.state.storedRoute;
                }
            }
            props.props.history.push({ pathname: `/project-module/ticket/${props.props.scheduleTicketId ? props.props.scheduleTicketId : scheduleTickekId}`, state: { storedRoute: storedRoute && storedRoute.length > 0 ? storedRoute : null } });
        }} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Preview Ticket </h1 >;
    } else if ((props.props.location.pathname === routes.FLUSER_MYTICKET)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`My Scheduled Tickets (${props.props.stMyTicketsList_total_tickets})`} </h1 >;
    } else if ((props.props.location.pathname === routes.TIMECARD_REVIEWED_LIST)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Timecard Request List (${props.props.timecardReviewedList_total_tickets})`} </h1 >;
    } else if ((props.props.location.pathname === routes.FL_TIMECARD_REVIEWED_LIST)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`My Timecard Requests (${props.props.timecardReviewedList_total_tickets})`} </h1 >;
    } else if ((props.props.location.pathname.includes(`preview/timecard-correction`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Back`} </h1 >;
    } else if ((props.props.location.pathname.includes(`time-sheet-list`))) {

        if (props?.props?.user?.role === userRoles.FRONTLINE) {
            return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`My Timesheet List (${props.props.timeSheetList_total_tickets})`} </h1 >;
        } else {
            return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {props.props.frontline_active_user_name} { `Timesheet List (${props.props.timeSheetList_total_tickets})`} </h1 >;
        }

    } else if ((props.props.location.pathname.includes(`mealpenalty-generator`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Meal Penalty </h1 >;
    } else if ((props.props.location.pathname.includes(`attendance-dashboard`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {props.props.frontline_active_user_name} Attendance Dashboard </h1 >;
    }
    else if ((props.props.location.pathname === routes.PERFORMANCE_AUDIT)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Performance Audit (${props.props.total_performanceAuditList_count})`} </h1 >;
    }
    else if ((props.props.location.pathname === routes.SELF_AUDIT)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {`Self Audit (${props.props.total_selfAuditList_count})`} </h1 >;
    }
    else if ((props.props.location.pathname.includes(`/performance-audit/view/`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> View Performance Audit </h1 >;
    }
    else if ((props.props.location.pathname.includes(`/performance-audit/${props.props.performanceAuditEditId}`))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Edit Performance Audit </h1 >;
    } else if ((props.props.location.pathname === routes.PEGASSURE_PLAYBOOK_LIST)) {
        return <h1 className="app_title" data-test="ticket_heder_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>PegAssure Playbook ({props.props.pegassurePlaybookList_total_tickets}) </h1 >;
    } else if ((props.props.location.pathname.includes(`/playbook-logarea-grid/`))) {
        return (
            <h1 className="app_title" data-test="ticket_heder_title">
                <a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a>
                <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>
                {props.props.playbookAreaHeaderUtils.totalArea ? `${props.props.playbookAreaHeaderUtils.playbookId + '/' + props.props.playbookAreaHeaderUtils.playbookTitle + ' ' + '(' + props.props.playbookAreaHeaderUtils.totalArea + ')'}` : 'Playbook'}
            </h1 >
        );
    } else if ((props.props.location.pathname.includes(`logbook-pdf`)) && (location.state.back)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Back </h1 >;
    }
    else if((props.props.location.pathname.includes(`/brm-schedule`))){
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> BRM Schedule({props.props.total_brmScheduleList_count}) </h1 >;
    }
    else if((props.props.location.pathname.includes(`/standard-matrix`))){
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {props?.props?.isStandardMatrix ? "Standard" : "Custom"} Matrix </h1 >;
    }
    else if (props.props.location.pathname === routes.JOBASSOCIATION_TABLE) {
        return <h1 className="app_title"> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Get Public Link ({props.props.total_PCT_association_count}) </h1>;
    }else if (props.props.location.pathname.includes(`preview-job-association`)) {
        return <h1 className="app_title"><a href="javascript:void(0)" onClick={() => props.props.history.goBack()} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a><span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span>Back </h1>;
    }
    
    else if ((props.props.location.pathname.includes('/fl-user-shift'))) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)" onClick={() => {props.props.history.goBack(); props.props.resetShiftFilters()}} className="mr-2"><img src="/images/icons/icn_back_arrow.png" alt="Icon" /></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> {props.props.frontline_active_user_name} Shifts ({props.props.total_shiftList_count})</h1 >;
    }

    else if ((props.props.location.pathname === routes.SAFETY_UNIT_LISTING)) {
        return <h1 className="app_title" data-test="preview_header_title"><a style={{ cursor: 'pointer' }} href="javascript:void(0)"  className="mr-2"></a> <span className="icn_navbar_title"><img src="/images/icons/icn_dashboard_gray.png" /></span> Unit Management ({props.props.safetyUnitManagement})</h1 >;
    }
}

const Header = (props) => {
    const NewInspectionActive = queryString.parse(props.props.location.search);
    if (props.props.token) {
        return (
            <header className="app-header mob_header">
                {/* <!-- Sidebar toggle button--> */}

                <a className="app-sidebar__toggle theme_toggle" href="javascript:void(0)" data-toggle="sidebar" aria-label="Hide Sidebar" onClick={props.props.toggleSideBar && props.isMobile ? null : props.sideBarClicked}>
                    <span className="toogle_left">
                        <i className="fa fa-bars" aria-hidden="true"></i>
                    </span>
                </a>
                <a className="app-header__logo" href="javascript:void(0)">
                    <span className="logo_group_themes">
                        <img style={{ width: '100%' }} className="app_logo xl_logo" src="/images/icons/pegasus_logo.png" alt="pegasus" />
                        <img className="app_logo xs_logo" src="/images/icons/mobile_logo.png" alt="Logo" />
                    </span>
                </a>
                {/* <!-- Navbar Right Menu--> */}
                {HeaderNames(props)}
                <ul className="app-nav">
                    {props.props.user && props.props.user.mode === 'all' && showModeToggleButton(props.props.location.pathname) && (!NewInspectionActive.newPerformInspection || NewInspectionActive.newPerformInspection === 'false') ?
                        <li>
                            {/* <!-- toggle btn --> */}
                            <div className="theme_switch_btn">
                                <span data-test="checkbox_internal" className="switch_title">Int<span className="mob_txt_hide">ernal</span></span>
                                <label data-test="toggle_switch">
                                    <input type="checkbox" checked={props.userMode} onClick={($event) => props.userModeClicked($event)} />
                                    <span></span>
                                    {/* <span className="ext_title d-none">Ext<span className="mob_txt_hide">ernal</span></span> */}
                                </label>
                                <span className="ext_title">Ext<span className="mob_txt_hide">ernal</span></span>
                            </div>
                            {/* <!-- toggle btn End --> */}
                        </li> : ''}
                    {/* <!-- User Menu--> */}
                    <li onClick={props.onProfileClick} data-test="main_profile_image">
                        <a id="edit_button_click" className="app-nav__item user_menu_dropdown" href="javascript:void(0)" data-toggle="modal" data-target="#editProfile" data-backdrop="static" data-keyboard="false">
                            <div class="media">
                                <span className="user_circle">
                                    {/* <img style={{ height: '40px', width: '40px', borderRadius: '50px' }} src={props.props.user.photo_urls.original ? props.props.user.photo_urls.original : "/customImages/user.png"} alt="User" /> */}
                                    <img style={{ height: '40px', width: '40px', borderRadius: '50px' }} src={props.props.user && props.props.user.photo_urls && props.props.user.photo_urls.original ? props.props.user.photo_urls.original : "/customImages/user.png"} alt="User" />
                                </span>
                                <div class="media-body">
                                    <span class="company_name">{makeFirstLetterCapital(props.props.user.first_name)}</span>
                                    <span className="user_txt">{makeFirstLetterCapital(props.props.user.role === userRoles.CLIENT ? 'Customer' : props.props.user.role)}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="logout" onClick={props.props.logout}>
                        <a className="app-menu__item" href="javascript:void (0)">
                            <span className="icon_gray">
                                <img src="/images/icons/icn_logout.png" className="app-menu__icon" />
                            </span>
                            <span className="app-menu__label nav-app-menu" data-test="logout">Logout</span>
                        </a>
                    </li>
                </ul>
            </header>

        )
    } else {

        return (
            <div className="no-back" id="signup-header">
                <div className="login-logo"><a href="#"><img style={{ height: `${props?.props?.location?.pathname?.includes('public-customer-ticket/new-ticket') ? '6.5rem' : '8.5rem'}` }} src="/images/icons/pegasus_gry_logo.png" alt="Pegasus" /></a></div>
            </div>
        )
    }
}

const Dashboard = (props) => {
    const settingsIndex = props.props.location.pathname.indexOf('/settings/');

    return (
        <li onClick={() => {
            props.updateLogbookSearchValues({ status: 'pending' });
            props.navigateTo(routes.DASHBORD)
        }
        }>
            <a className={(((props.props.location.pathname === routes.DASHBORD) || (props.props.location.pathname === routes.HOME) || (props.props.location.pathname === routes.VIEW_FILTERED_QUOTES)) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_dashboard_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_dashboard_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label">Dashboard</span>
            </a>
        </li>
    )
}

const Quotes = (props) => {

    return (
        <li onClick={() => { props.navigateTo(routes.QUOTES); props.quotePageClicked(0) }}>
            <a className={(((props.props.location.pathname === routes.QUOTES) || (props.props.location.pathname === routes.NEW_QUOTES)) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_quotes_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_quotes_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label" data-test="sidenav_quote_nav">Quotes</span>
            </a>
        </li>
    )
}

const PegassureLogbookLogs = (props) => {

    return (
        <li onClick={() => {
            props.updateLogbookSearchValues({ status: 'pending' });
            props.navigateTo(routes.LOGBOOK)
        }}>
            <a className={(((props.props.location.pathname === routes.LOGBOOK)) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_quotes_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_quotes_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label" data-test="sidenav_quote_nav">PegAssure Logbook Logs</span>
            </a>
        </li>
    )
}

// const Companies = (props) => {
//     // if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.ADMIN) {
//     const index = props.props.location.pathname.indexOf('/companies/');
//     return (
//         <li onClick={() => {props.navigateTo(routes.COMPANIES); props.companyPageClicked(0)}}>
//             <a className={(((props.props.location.pathname === routes.COMPANIES) || (props.props.location.pathname === routes.NEW_COMPANY) || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
//                 <span className="icons_gray">
//                     <img src="/images/icons/icn_companies_gray.png" className="app-menu__icon" />
//                 </span>
//                 <span className="icons_white">
//                     <img src="/images/icons/icn_companies_white.png" className="app-menu__icon" />
//                 </span>
//                 <span className="app-menu__label">Customers</span>
//             </a>
//         </li>
//     )
//     // }
// }

const customerInfo = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.COMPANIES); props.companyPageClicked(0) }} data-test="inspection_forms_btn">Customer Info</a></li>
    )
}

const APIAccess = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.API_ACCESS); props.companyPageClicked(0) }} data-test="inspection_forms_btn">API Access</a></li>
    )
}

const BRMSchedule = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.BRM_Schedule); props.companyPageClicked(0) }} data-test="inspection_forms_btn">BRM Schedule</a></li>
    )
}

const renderCustomerModule = (props) => {

    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        return (
            <>
                {customerInfo(props)}
                {APIAccess(props)}
                {BRMSchedule(props)}
            </>
        )

    } else if (props.props.user.role === userRoles.ADMIN) {

        return (
            <>
                {customerInfo(props)}
                {BRMSchedule(props)}
            </>
        )
    } else if (props.props.user.role === userRoles.INSPECTOR) {
        return (
            <>
                {customerInfo(props)}
                {/* {BRMSchedule(props)} */}
            </>
        )
    } else if (props.props.user.role === userRoles.CLIENT) {
        return (
            <>
                {customerInfo(props)}
            </>
        )
    } else if (props.props.user.role === userRoles.REQUESTOR) {
        return (
            <>
                {customerInfo(props)}
            </>
        )
    }

}

const Companies = (props) => {
    const index = props.props.location.pathname.indexOf('/companies/');
    return (
        <li className={props.props.toggleCustomer ? "treeview is-expanded" : "treeview"}>
            <a className={props.settingActive || index > -1 ? "app-menu__item active" : "app-menu__item"} href="#customer" data-toggle="treeview" onClick={props.customerClicked}>
                <span className="icons_gray">
                    <img src="/images/icons/icn_companies_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_companies_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label" data-test="settings_navigate_btn">Customers</span>
                <i className="treeview-indicator fa fa-angle-down"></i>
            </a>
            <ul className="treeview-menu" data-test="settings_tree_view">
                {renderCustomerModule(props)}
            </ul>
        </li>
    )
}

const NewVerion = (props) => {
    // if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.ADMIN) {
    const index = props.props.location.pathname.indexOf('/companies/');
    return (
        <li onClick={() => { props.navigateTo(routes.NEW_VERION); props.companyPageClicked(0) }}>
            <a className={(((props.props.location.pathname === routes.NEW_VERION) || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_inspection_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_inspection_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label">Learning Center</span>
            </a>
        </li>
    )
    // }
}

const Accounts = (props) => {
    // if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.ADMIN) {
    const index = props.props.location.pathname.indexOf('/accounts/');
    return (
        <li onClick={() => {
            props.navigateTo(routes.ACCOUNTS);
            props.accountPageClicked(0)
        }} >
            <a className={(((props.props.location.pathname === routes.ACCOUNTS) || (props.props.location.pathname === routes.NEW_ACCOUNTS) || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_account_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_account_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label" data-test="jobs_select">Jobs</span>
            </a>
        </li>
    )
    // }
}



const Schedules = (props) => {

    // if (props.props.user.role === userRoles.SUPER_ADMIN) {
    const index = props.props.location.pathname.indexOf('/schedules/');
    return (
        <li onClick={() => props.navigateTo(routes.SCHEDULE_TAB)} data-test="users_main_menu">
            <a className="app-menu__item" href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_survey_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_survey_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label">Schedules</span>
            </a>
        </li>

    )
    // }
}

// if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.ADMIN) {
const GeneralInspectionForms = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.INSPECTIONS); props.inspectionPageClicked(0); props.resetInspectionSearch(); storage.remove('inspectionId') }} data-test="inspection_forms_btn">All Inspections</a></li>
    )
}
const MyInspectionForms = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.MY_INSPECTIONS); props.inspectionPageClicked(0); props.resetInspectionSearch(); storage.remove('inspectionId') }} data-test="inspection_forms_btn">My Inspections</a></li>
    )
}
const PendingInspectionForms = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.PENDING_INSPECTIONS); props.inspectionPageClicked(0); props.resetInspectionSearch(); storage.remove('inspectionId') }} data-test="inspection_forms_btn">Pending Inspections</a></li>
    )
}
const renderInspectionsModule = (props) => {
    const isAccountOwner = storage.get('isAccountOwner');

    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        return (
            <>
                {GeneralInspectionForms(props)}
                {MyInspectionForms(props)}
                {PendingInspectionForms(props)}
            </>
        )

    } else if (props.props.user.role === userRoles.ADMIN) {

        return (
            <>
                {GeneralInspectionForms(props)}
                {MyInspectionForms(props)}
                {isAccountOwner ? PendingInspectionForms(props) : null}
            </>
        )
    } else if (props.props.user.role === userRoles.INSPECTOR) {
        return (
            <>
                {GeneralInspectionForms(props)}
                {MyInspectionForms(props)}
                {isAccountOwner ? PendingInspectionForms(props) : null}
            </>
        )
    } else if (props.props.user.role === userRoles.CLIENT) {
        return (
            <>
                {GeneralInspectionForms(props)}
                {/* {MyInspectionForms(props)} */}
                {isAccountOwner ? PendingInspectionForms(props) : null}
            </>
        )
    } else if (props.props.user.role === userRoles.REQUESTOR) {
        return (
            <>
                {GeneralInspectionForms(props)}
                {MyInspectionForms(props)}
                {isAccountOwner ? PendingInspectionForms(props) : null}
            </>
        )
    }

}

const Inspections = (props) => {

    if (props.props.user.role === userRoles.CLIENT) {
        const index = props.props.location.pathname.indexOf('/inspections/');
        return (
            <li onClick={() => { props.navigateTo(routes.INSPECTIONS); props.inspectionPageClicked(0); props.resetInspectionSearch(); storage.remove('inspectionId') }} data-test="users_main_menu">
                <a className="app-menu__item" href="javascript:void(0)">
                    <span className="icons_gray">
                        <img src="/images/icons/icn_inspection_gray.png" className="app-menu__icon" />
                    </span>
                    <span className="icons_white">
                        <img src="/images/icons/icn_inspection_white.png" className="app-menu__icon" />
                    </span>
                    <span className="app-menu__label"> All Inspections </span>
                </a>
            </li>

        )
    } else {
        const index = props.props.location.pathname.indexOf('/inspections/');
        return (
            <li className={props.props.inspectionToggleSettings ? "treeview is-expanded" : "treeview"}>
                <a className={props.settingActive || index > -1 ? "app-menu__item active" : "app-menu__item"} href="#inspections" data-toggle="treeview" onClick={props.inspectionsClicked}>
                    <span className="icons_gray">
                        <img src="/images/icons/icn_inspection_gray.png" className="app-menu__icon" />
                    </span>
                    <span className="icons_white">
                        <img src="/images/icons/icn_inspection_white.png" className="app-menu__icon" />
                    </span>
                    <span className="app-menu__label" data-test="settings_navigate_btn">Inspections</span>
                    <i className="treeview-indicator fa fa-angle-down"></i>
                </a>
                <ul className="treeview-menu" data-test="settings_tree_view">
                    {renderInspectionsModule(props)}
                </ul>
            </li>
        )
    }
}

const Reports = (props) => {
    // if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.ADMIN) {
    const index = props.props.location.pathname.indexOf('/reports/');
    return (
        <li onClick={() => props.navigateTo(routes.REPORTS)}>
            <a className={(((props.props.location.pathname === routes.REPORTS) || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_reports_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_reports_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label ml-1">Reports</span>
            </a>
        </li>
    )
    // }
}

const CHI_Servy = (props) => {
    // const index = props.props.location.pathname.indexOf('/reports/');
    return (
        <li onClick={() => props.navigateTo(routes.CHI_SERVYS)}>
            <a className={(((props.props.location.pathname === routes.CHI_SERVYS)) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_survey_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_survey_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label ml-1 dispContent">CHI Surveys</span>
            </a>
        </li>
    )
}

const Criteria = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => props.navigateTo(routes.CRITERIA)}>Criteria</a></li>
    )
}

const PegassurePlaybookList = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => props.navigateTo(routes.PEGASSURE_PLAYBOOK_LIST)}>PegAssure Playbook</a></li>
    )
}
const renderTicketsModule = (props) => {

    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        return (
            <>
                {GeneralTicketsForm(props)}
                {MyTicketsForm(props)}
                {ScheduledTicketsForm(props)}
                {JobAssociationTicketsForm(props)}
            </>
        )

    } else if (props.props.user.role === userRoles.ADMIN) {

        return (
            <>
                {GeneralTicketsForm(props)}
                {MyTicketsForm(props)}
                {ScheduledTicketsForm(props)}
            </>
        )
    } else if (props.props.user.role === userRoles.INSPECTOR) {
        return (
            <>
                {GeneralTicketsForm(props)}
                {MyTicketsForm(props)}
                {ScheduledTicketsForm(props)}

            </>
        )
    } else if (props.props.user.role === userRoles.CLIENT) {
        return (
            <>
                {GeneralTicketsForm(props)}
                {MyTicketsForm(props)}
                {/* {ScheduledTicketsForm(props)} */}
            </>
        )
    } else if (props.props.user.role === userRoles.REQUESTOR) {
        return (
            <>
                {GeneralTicketsForm(props)}
                {MyTicketsForm(props)}
                {/* {ScheduledTicketsForm(props)} */}
            </>
        )
    }

}

const renderSafetysModule = (props) => {

    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        return (
            <>
                {vehicleModule(props)}
                {golfCartModule(props)}
                {trailerModule(props)}
                {UnitManagement(props)}
            </>
        )

    } else if (props.props.user.role === userRoles.ADMIN) {

        return (
            <>
                 {vehicleModule(props)}
                {golfCartModule(props)}
                {trailerModule(props)}
                {UnitManagement(props)}
            </>
        )
    } else if (props.props.user.role === userRoles.INSPECTOR) {
        return (
            <>
                {vehicleModule(props)}
                {golfCartModule(props)}
                {trailerModule(props)}
                {UnitManagement(props)}

            </>
        )
    }  else if (props.props.user.role === userRoles.REQUESTOR) {
        return (
            <>
                 {vehicleModule(props)}
                {golfCartModule(props)}
                {trailerModule(props)}
                {UnitManagement(props)}
            </>
        )
    } else if (props.props.user.role === userRoles.FRONTLINE) {
        return (
            <>
                 {vehicleModule(props)}
                {golfCartModule(props)}
                {trailerModule(props)}
            </>
        )
    }

}
const HighlightPath = (history, path) => {

    return history.location.pathname.split("/")[1] === path ? "active" : ""
}

const teamChecklist = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "team-checklist")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.TEAM_CHECKLIST); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Team Checklist</a></li>
    )
}

const teamChecklistLog = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "team-checklist-log")}`} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.TEAM_CHECKLIST_LOG); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Team Checklist Log</a></li>
    )
}

const teamChecklistSupervisorSummary = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "team-checklist-supervisior-summary")}`} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.TEAM_CHECKLIST_SUPERVISIOR_SUMMARY); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Checklist Supervisor Summary</a></li>
    )
}

const teamChecklistManagerSummary = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "team-checklist-manager-summary")}`} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.TEAM_CHECKLIST_MANAGER_SUMMARY); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Checklist Manager Summary</a></li>
    )
}

const trainingLog = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "training-log")}`} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.TRAINING_LOG); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Training log</a></li>
    )
}

const renderTeamManagementModule = (props) => {
    const formAccess = props && props.user.form_access_actions

    return (
        <>
            {formAccess?.team_management.checklist.view ? teamChecklist(props) : null}
            {formAccess?.team_management.checklist_log.view ? teamChecklistLog(props) : null}
            {formAccess?.team_management.checklist_supervisor_summary.view ? teamChecklistSupervisorSummary(props) : null}
            {formAccess?.team_management.checklist_manager.view ? teamChecklistManagerSummary(props) : null}
            {formAccess?.team_management.training_log.view ? trainingLog(props) : null}
        </>
    )


}

const restroomPortering = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "rest-room-portering")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.RESTROOM_PORTERING); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Porter Log</a></li>
    )
}

const renderLogbookModule = (props) => {
    const formAccess = props && props.user.form_access_actions
    return (
        <>
            {formAccess?.logbook.porter_log.view ? restroomPortering(props) : null}
        </>
    )
}

const buildingProfile = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "building-profile")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.BUILDING_PROFILE); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Building Profile</a></li>
    )
}

const jobCards = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "job-core-map-card")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.JOB_CORE_MAP_CARD); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Job Cards</a></li>
    )
}

const renderBuildingProfileModule = (props) => {
    const formAccess = props && props.user.form_access_actions

    return (
        <>
            {formAccess?.building_profiles?.building_profile?.view ? buildingProfile(props) : null}
            {formAccess?.building_profiles?.job_cards?.view ? jobCards(props) : null}
        </>
    )

}

const BulkStorage = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "building-profile")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.NEW_BUILDING_PROFILE); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Bulk Storage</a></li>
    )
}

const AssetsList = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "assets")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.ASSETS_LIST); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Asset List</a></li>
    )
}

const AssetsCheckIn = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "checkIn_qr")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.ASSET_SCAN_CHECK_IN_QR) }}>Asset Check In</a></li>
    )
}

const AssetsCheckOut = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "checkOut_qr")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.ASSET_SCAN_CHECK_OUT_QR) }}>Asset Check Out</a></li>
    )
}

const DailyKitting = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "daily-kitting")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.DAILY_KITTING); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Daily Kitting</a></li>
    )
}

const SelfAudit = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "self-audit")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.SELF_AUDIT); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Self Audit</a></li>
    )
}

const MonthlyUsageReports = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "monthly-usage-report")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.MONTHLY_USAGE_REPORT); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Monthly Usage Reports</a></li>
    )
}


const BulkStorageForm = (props) => {
    const handleClick = (props) => {
        props.history.push({
            search: "?bulkstorage=true"
        })
    }
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "bulk-storage")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(`${routes.NEW_INVENTORY_PULL_REQUEST_TICKET}`); props.inspectionPageClicked(0); handleClick(props) }} data-test="inspection_forms_btn">Bulk Storage Supplies</a></li>
    )
}


const MonthlyCostSummaryAnalysis = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "monthly-cost-summary-analysis")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.MONTHLY_COST_SUMMARY_ANALYSIS); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Monthly Cost Summary</a></li>
    )
}



const renderAssetsModule = (props) => {

    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        return (
            <>
                {AssetsList(props)}
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? AssetsCheckOut(props) : null}
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? AssetsCheckIn(props) : null}

            </>
        )

    } else if (props.props.user.role === userRoles.ADMIN) {

        return (
            <>
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager" ? AssetsList(props) : null}
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? AssetsCheckOut(props) : null}
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? AssetsCheckIn(props) : null}
            </>
        )
    }

    else if (props.props.user.role === userRoles.INSPECTOR) {

        return (
            <>
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager" ? AssetsList(props) : null}
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? AssetsCheckOut(props) : null}
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? AssetsCheckIn(props) : null}
            </>
        )
    }

    else if (props.props.user.role === userRoles.REQUESTOR) {

        return (
            <>
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager" ? AssetsList(props) : null}
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? AssetsCheckOut(props) : null}
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? AssetsCheckIn(props) : null}
            </>
        )
    }

}

const Asset = (props) => {
    return (
        <li>
            <div id="IVa" class="cst_accordion_menu">
                <div class="card-header" id="heading4a">
                    <a href="javascript:void(0)" class="app-menu__item settings_menu" data-toggle="collapse" data-target="#collapseIVa" aria-expanded="true" aria-controls="collapseIVa">
                        <span className="app-menu__label">Assets</span>
                    </a>
                </div>
                <div id="collapseIVa" class="collapse" aria-labelledby="heading4a" data-parent="#IVa">
                    <div class="card-body">
                        <ul class="inner_items">
                            {renderAssetsModule(props)}
                        </ul>
                    </div>
                </div>
            </div>
        </li>
    )
}



const renderInventoryModule = (props) => {
    const formAccess = props && props.user.form_access_actions
    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        return (
            <>
                {Asset(props)}
                {formAccess?.inventory.daily_kitting_safety.view ? DailyKitting(props) : null}
                {formAccess?.inventory.bulk_storage_supplies_usage.create ? BulkStorageForm(props) : null}
                {formAccess?.inventory.monthly_usage_report.view ? MonthlyUsageReports(props) : null}
                {formAccess?.inventory.monthly_cost_summary.view ? MonthlyCostSummaryAnalysis(props) : null}
            </>
        )

    } else if (props.props.user.role === userRoles.ADMIN) {

        return (
            <>
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager" || props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? Asset(props) : null}
                {formAccess?.inventory.daily_kitting_safety.view ? DailyKitting(props) : null}
                {formAccess?.inventory.bulk_storage_supplies_usage.create ? BulkStorageForm(props) : null}
                {formAccess?.inventory.monthly_usage_report.view ? MonthlyUsageReports(props) : null}
                {formAccess?.inventory.monthly_cost_summary.view ? MonthlyCostSummaryAnalysis(props) : null}
            </>
        )
    }
    else if (props.props.user.role === userRoles.INSPECTOR) {

        return (
            <>
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager" || props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? Asset(props) : null}
                {formAccess?.inventory.daily_kitting_safety.view ? DailyKitting(props) : null}
                {formAccess?.inventory.bulk_storage_supplies_usage.create ? BulkStorageForm(props) : null}
                {formAccess?.inventory.monthly_usage_report.view ? MonthlyUsageReports(props) : null}
                {formAccess?.inventory.monthly_cost_summary.view ? MonthlyCostSummaryAnalysis(props) : null}
            </>
        )
    }
    else if (props.props.user.role === userRoles.REQUESTOR) {

        return (
            <>
                {props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager" || props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ? Asset(props) : null}
                {formAccess?.inventory.daily_kitting_safety.view ? DailyKitting(props) : null}
                {formAccess?.inventory.bulk_storage_supplies_usage.create ? BulkStorageForm(props) : null}
                {formAccess?.inventory.monthly_usage_report.view ? MonthlyUsageReports(props) : null}
                {formAccess?.inventory.monthly_cost_summary.view ? MonthlyCostSummaryAnalysis(props) : null}
            </>
        )
    }

}



const DeepCleanChart = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "deep-clean-chart")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.DEEP_CLEAN_CHART); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Deep Clean Chart</a></li>
    )
}

const DeepClean = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "project-module")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.PROJECT_MODULE); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Deep Clean Schedule Tickets</a></li>
    )
}

const renderProjectModule = (props) => {
    const formAccess = props && props.user.form_access_actions
    return (
        <>
            {formAccess?.projects.deep_clean_chart.view ? DeepCleanChart(props) : null}
            {formAccess?.projects.deep_clean_chart.view ? DeepClean(props) : null}
        </>
    )

}

const IndexCard = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "requset-form")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.REQUSET_FORM); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Index Card</a></li>
    )
}

const renderTagModule = (props) => {
    const formAccess = props && props.user.form_access_actions
    return (
        <>
            {formAccess?.tag.request_form.view ? IndexCard(props) : null}
        </>
    )

}

const selfAudit = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "self-audit")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.SELF_AUDIT); props.inspectionPageClicked(0) }} data-test="inspection_forms_btn">Self Audit</a></li>
    )
}

const performanceAudit = (props) => {
    return (
        <li><a className={`dropdown-item treeview-item ${HighlightPath(props.history, "performance-audit")} `} href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.PERFORMANCE_AUDIT); props.performanceAuditPageClicked(); }} data-test="inspection_forms_btn">Performance Audit</a></li>
    )
}

const renderAuditModule = (props) => {
    const formAccess = props && props.user.form_access_actions
    return (
        <>
            {formAccess?.audit?.self_audit.view ? selfAudit(props) : null}
            {formAccess?.audit?.performance_audit.view ? performanceAudit(props) : null}
        </>
    )

}

//safety module sub items starts

const vehicleModule = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.VEHICLE_SAFETY_FORM)}} data-test="inspection_forms_btn">Vehicle Inspection</a></li>
    )
}

const  golfCartModule = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.GOLF_CART_SAFETY_FORM)}} data-test="inspection_forms_btn">Golf Cart Inspection</a></li>
    )
}

const trailerModule = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.TRAILER_SAFETY_FORM)}} data-test="inspection_forms_btn">Trailer Inspection</a></li>
    )
}

const UnitManagement = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.SAFETY_UNIT_LISTING)}} data-test="inspection_forms_btn">Unit Management</a></li>
    )
}

//safety module sub items ends


const GeneralTicketsForm = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.TICKETS); props.inspectionPageClicked(0); props.ticketPageClicked(0); props.resetTicketSearch(); storage.remove('ticketId') }} data-test="inspection_forms_btn">All Tickets</a></li>
    )
}

const MyTicketsForm = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.MY_TICKETS); props.inspectionPageClicked(0); props.ticketPageClicked(0); props.resetTicketSearch(); storage.remove('ticketId') }} data-test="inspection_forms_btn">My Tickets</a></li>
    )
}

const ScheduledTicketsForm = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.SCHEDULED_TICKETS); props.inspectionPageClicked(0); props.ticketPageClicked(0); props.resetTicketSearch(); storage.remove('ticketId') }} data-test="inspection_forms_btn">Scheduled Tickets</a></li>
    )
}
const JobAssociationTicketsForm = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.JOBASSOCIATION_TABLE); props.inspectionPageClicked(0); props.ticketPageClicked(0); props.resetTicketSearch(); storage.remove('ticketId') }} data-test="inspection_forms_btn">Get Public Link</a></li>
    )
}
const Tickets = (props) => {
    // if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.ADMIN) {
    const index = props.props.location.pathname.indexOf('/tickets/');
    return (
        <li className={props.props.ticketToggle ? "treeview is-expanded" : "treeview"}>
            <a className={props.settingActive || index > -1 ? "app-menu__item active" : "app-menu__item"} href="#tickets" data-toggle="treeview" onClick={props.ticketsClicked}>
                <span className="icons_gray">
                    <img src="/images/icons/icn_inspection_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_inspection_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label" data-test="settings_navigate_btn">Tickets</span>
                <i className="treeview-indicator fa fa-angle-down"></i>
            </a>
            <ul className="treeview-menu" data-test="settings_tree_view">
                {renderTicketsModule(props)}
            </ul>
        </li>
    )
    // }
}

const Safety = (props) => {
    const index = props.props.location.pathname.indexOf('/safety/');
    return (
        <li className={props.props.safetyToggle ? "treeview is-expanded" : "treeview"}>
            <a className={props.settingActive || index > -1 ? "app-menu__item active" : "app-menu__item"} href="#tickets" data-toggle="treeview" onClick={props.safetyClicked}>
                <span className="icons_gray">
                    <img src="/images/icons/icn_inspection_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_inspection_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label" data-test="settings_navigate_btn">Safety</span>
                <i className="treeview-indicator fa fa-angle-down"></i>
            </a>
            <ul className="treeview-menu" data-test="settings_tree_view">
                {renderSafetysModule(props)}
            </ul>
        </li>
    )
    // }
}

const os1 = (props) => {
    const formAccess = props && props.user.form_access_actions

    // if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.ADMIN) {
    const index = props.props.location.pathname.indexOf('/team-checklist-log/');
    
    const handleOS1Click = () => {
        props.os1Clicked();
    };
    // get team checlist view
    const teamChecklist = []
    for (const key in formAccess?.team_management) {
        if (formAccess?.team_management.hasOwnProperty(key)) {
            const obj = {
                [key]: formAccess?.team_management[key]
            };
            teamChecklist.push(obj);
        }
    }
    const updatedTeamChecklist = teamChecklist.filter((item, i) => {
        const value = Object.keys(item)[0]
        return item[value].view === true
    })
    // get building profile view
    const BpList = []
    for (const key in formAccess?.building_profiles) {
        if (formAccess?.building_profiles.hasOwnProperty(key)) {
            const obj = {
                [key]: formAccess?.building_profiles[key]
            };
            BpList.push(obj);
        }
    }
    const updatedBplist = BpList.filter((item, i) => {
        const value = Object.keys(item)[0]
        return item[value].view === true
    })
    // get Inventory view
    const inventoryList = []
    for (const key in formAccess?.inventory) {
        if (formAccess?.inventory.hasOwnProperty(key)) {
            const obj = {
                [key]: formAccess?.inventory[key]
            };
            inventoryList.push(obj);
        }
    }
    const updatedInventorylist = inventoryList.filter((item, i) => {
        const value = Object.keys(item)[0]
        return item[value].view === true
    })
    // get Audits view
    const auditList = []
    for (const key in formAccess?.audit) {
        if (formAccess?.audit.hasOwnProperty(key)) {
            const obj = {
                [key]: formAccess?.audit[key]
            };
            auditList.push(obj);
        }
    }
    const updatedAuditList = auditList.filter((item, i) => {
        const value = Object.keys(item)[0]
        return item[value].view === true
    })
    return (
        <>
            {updatedAuditList.length > 0 || updatedTeamChecklist.length > 0 || updatedBplist.length > 0 || formAccess?.logbook.porter_log.view || formAccess?.projects.deep_clean_chart.view || formAccess?.tag.request_form.view || updatedInventorylist.length > 0 || props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager" || props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ?
                <li className={props.props.os1Toggle ? "treeview is-expanded" : "treeview"}>
                    <a className={props.settingActive || index > -1 ? "app-menu__item active" : "app-menu__item"} href="#os1" data-toggle="treeview" onClick={handleOS1Click}>
                        <span className="icons_gray">
                            <img src="/images/icons/icn_inspection_gray.png" className="app-menu__icon" />
                        </span>
                        <span className="icons_white">
                            <img src="/images/icons/icn_inspection_white.png" className="app-menu__icon" />
                        </span>
                        <span className="app-menu__label" data-test="settings_navigate_btn">OS1</span>
                        <i className="treeview-indicator fa fa-angle-down"></i>
                    </a>
                    <ul className="treeview-menu" data-test="settings_tree_view">
                        {updatedTeamChecklist.length > 0 ? <li className="setting_dropdown">
                            <div id="TM" class="cst_accordion_menu">
                                <div class="card-header" id="headingOne">
                                    <a className={(index > -1) ? "app-menu__item settings_menu active" : "app-menu__item settings_menu"} href="javascript:void(0)" class="app-menu__item settings_menu" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <span className="icons_gray">
                                            <img src="/images/icons/icn_users.png" className="app-menu__icon" />
                                        </span>
                                        <span className="icons_white">
                                            <img src="/images/icons/icn_users_white.png" className="app-menu__icon" />
                                        </span>
                                        <span className="app-menu__label">Team Management</span>
                                    </a>
                                </div>
                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#TM">
                                    <div class="card-body">
                                        <ul class="inner_items">
                                            {renderTeamManagementModule(props)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li> : null}
                        {formAccess?.logbook.porter_log.view ? <li className="setting_dropdown">
                            <div id="LB" class="cst_accordion_menu">
                                <div class="card-header" id="heading2">
                                    <a className={(index > -1) ? "app-menu__item settings_menu active" : "app-menu__item settings_menu"} href="javascript:void(0)" class="app-menu__item settings_menu" data-toggle="collapse" data-target="#collapseLogbook" aria-expanded="true" aria-controls="collapseLogbook">
                                        <span className="icons_gray">
                                            <img src="/images/icons/icn_tickets_gray.png" className="app-menu__icon" />
                                        </span>
                                        <span className="icons_white">
                                            <img src="/images/icons/icn_tickets_white.png" className="app-menu__icon" />
                                        </span>
                                        <span className="app-menu__label">Logbook</span>
                                    </a>
                                </div>
                                <div id="collapseLogbook" class="collapse" aria-labelledby="heading2" data-parent="#LB">
                                    <div class="card-body">
                                        <ul class="inner_items">
                                            {renderLogbookModule(props)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li> : null}
                        {updatedBplist.length > 0 ? <li className="setting_dropdown">
                            <div id="BP" class="cst_accordion_menu">
                                <div class="card-header" id="heading3">
                                    <a className={(index > -1) ? "app-menu__item settings_menu active" : "app-menu__item settings_menu"} href="javascript:void(0)" class="app-menu__item settings_menu" data-toggle="collapse" data-target="#collapseBP" aria-expanded="true" aria-controls="collapseBP">
                                        <span className="icons_gray">
                                            <img src="/images/icons/icn_tickets_gray.png" className="app-menu__icon" />
                                        </span>
                                        <span className="icons_white">
                                            <img src="/images/icons/icn_tickets_white.png" className="app-menu__icon" />
                                        </span>
                                        <span className="app-menu__label">Building Profiles</span>
                                    </a>
                                </div>
                                <div id="collapseBP" class="collapse" aria-labelledby="heading3" data-parent="#BP">
                                    <div class="card-body">
                                        <ul class="inner_items">
                                            {renderBuildingProfileModule(props)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li> : null}
                        {updatedInventorylist.length > 0 || props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager" || props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor" ?
                            <li className="setting_dropdown">
                                <div id="IV" class="cst_accordion_menu">
                                    <div class="card-header" id="heading4">
                                        <a className={(index > -1) ? "app-menu__item settings_menu active" : "app-menu__item settings_menu"} href="javascript:void(0)" class="app-menu__item settings_menu" data-toggle="collapse" data-target="#collapseIV" aria-expanded="true" aria-controls="collapseIV">
                                            <span className="icons_gray">
                                                <img src="/images/icons/icn_tickets_gray.png" className="app-menu__icon" />
                                            </span>
                                            <span className="icons_white">
                                                <img src="/images/icons/icn_tickets_white.png" className="app-menu__icon" />
                                            </span>
                                            <span className="app-menu__label">Inventory Module</span>
                                        </a>
                                    </div>
                                    <div id="collapseIV" class="collapse" aria-labelledby="heading4" data-parent="#IV">
                                        <div class="card-body">
                                            <ul class="inner_items">
                                                {renderInventoryModule(props)}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            : null
                        }
                        {formAccess?.projects.deep_clean_chart.view ? <li className="setting_dropdown">
                            <div id="PM" class="cst_accordion_menu">
                                <div class="card-header" id="heading5">
                                    <a className={(index > -1) ? "app-menu__item settings_menu active" : "app-menu__item settings_menu"} href="javascript:void(0)" class="app-menu__item settings_menu" data-toggle="collapse" data-target="#collapsePM" aria-expanded="true" aria-controls="collapsePM">
                                        <span className="icons_gray">
                                            <img src="/images/icons/icn_tickets_gray.png" className="app-menu__icon" />
                                        </span>
                                        <span className="icons_white">
                                            <img src="/images/icons/icn_tickets_white.png" className="app-menu__icon" />
                                        </span>
                                        <span className="app-menu__label">Projects Module</span>
                                    </a>
                                </div>
                                <div id="collapsePM" class="collapse" aria-labelledby="heading5" data-parent="#PM">
                                    <div class="card-body">
                                        <ul class="inner_items">
                                            {renderProjectModule(props)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li> : null}
                        {formAccess?.tag.request_form.view ? <li className="setting_dropdown">
                            <div id="TM" class="cst_accordion_menu">
                                <div class="card-header" id="heading6">
                                    <a className={(index > -1) ? "app-menu__item settings_menu active" : "app-menu__item settings_menu"} href="javascript:void(0)" class="app-menu__item settings_menu" data-toggle="collapse" data-target="#collapseTM" aria-expanded="true" aria-controls="collapseTM">
                                        <span className="icons_gray">
                                            <img src="/images/icons/icn_tickets_gray.png" className="app-menu__icon" />
                                        </span>
                                        <span className="icons_white">
                                            <img src="/images/icons/icn_tickets_white.png" className="app-menu__icon" />
                                        </span>
                                        <span className="app-menu__label">Tag Module</span>
                                    </a>
                                </div>
                                <div id="collapseTM" class="collapse" aria-labelledby="heading6" data-parent="#TM">
                                    <div class="card-body">
                                        <ul class="inner_items">
                                            {renderTagModule(props)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li> : null}
                        {updatedAuditList.length > 0 ? <li className="setting_dropdown">
                            <div id="AUDIT" class="cst_accordion_menu">
                                <div class="card-header" id="heading7">
                                    <a className={(index > -1) ? "app-menu__item settings_menu active" : "app-menu__item settings_menu"} href="javascript:void(0)" class="app-menu__item settings_menu" data-toggle="collapse" data-target="#collapseAU" aria-expanded="true" aria-controls="collapseAU">
                                        <span className="icons_gray">
                                            <img src="/images/icons/icn_tickets_gray.png" className="app-menu__icon" />
                                        </span>
                                        <span className="icons_white">
                                            <img src="/images/icons/icn_tickets_white.png" className="app-menu__icon" />
                                        </span>
                                        <span className="app-menu__label">Audits</span>
                                    </a>
                                </div>
                                <div id="collapseAU" class="collapse" aria-labelledby="heading7" data-parent="#AUDIT">
                                    <div class="card-body">
                                        <ul class="inner_items">
                                            {renderAuditModule(props)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                            : null}
                    </ul>
                </li>
                : null}

        </>
    )
    // }
}

const FrontlineUsers = (props) => {

    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.FRONTLINE_LIST); props.frontlineUserPageClicked(0) }} data-test=""> Frontline Users </a></li>
    )
    // }
}

const ScheduledTask = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.SCHEDULED_TASKS); }} data-test=""> Scheduled Tasks </a></li>
    )
}

const shiftSchedule = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.SHIFT_SCHEDULE); }} data-test=""> Shifts </a></li>
    )
}

const swapList = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.SWAP_LIST); }} data-test=""> Swap Requests </a></li>
    )
}

const overrideAvailabilityList = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.OVERRIDE_AVAILABILITY_REQUEST_LIST); }} data-test=""> Override Requests </a></li>
    )
}

const availabilityList = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.AVAILABILITY_LIST); }} data-test=""> Availability List </a></li>
    )
}

const TimecardReviewedList = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.TIMECARD_REVIEWED_LIST); props.timecardReviewedListPageClicked(0) }} data-test=""> Timecard Request List </a></li>
    )
}

const renderFrontlineModule = (props) => {

    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        return (
            <>
                {FrontlineUsers(props)}
                {ScheduledTask(props)}
                {shiftSchedule(props)}
                {swapList(props)}
                {availabilityList(props)}
                {overrideAvailabilityList(props)}
                {TimecardReviewedList(props)}
            </>
        )

    } else if (props.props.user.role === userRoles.ADMIN) {

        return (
            <>
                {FrontlineUsers(props)}
                {ScheduledTask(props)}
                {shiftSchedule(props)}
                {swapList(props)}
                {availabilityList(props)}
                {overrideAvailabilityList(props)}
                {TimecardReviewedList(props)}
            </>
        )

    } else if (props.props.user.role === userRoles.INSPECTOR) {

        return (
            <>
                {FrontlineUsers(props)}
                {ScheduledTask(props)}
                {shiftSchedule(props)}
                {swapList(props)}
                {availabilityList(props)}
                {overrideAvailabilityList(props)}
                {TimecardReviewedList(props)}
            </>
        )

    }
}

const Frontline = (props) => {
    // if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.ADMIN) {
    const index = props.props.location.pathname.indexOf('/frontlines/');
    return (
        <li className={props.props.frontlineToggle ? "treeview is-expanded" : "treeview"}>
            <a className={props.settingActive || index > -1 ? "app-menu__item active" : "app-menu__item"} href="#tickets" data-toggle="treeview" onClick={props.frontlineClicked}>
                <span className="icons_gray">
                    <img src="/images/icons/icn_users.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_users_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label" data-test="settings_navigate_btn">Frontline</span>
                <i className="treeview-indicator fa fa-angle-down"></i>
            </a>
            <ul className="treeview-menu" data-test="settings_tree_view">
                {renderFrontlineModule(props)}
            </ul>
        </li>
    )
    // }
}

const PegLogbookQRScan = (props) => {
    const index = props.props.location.pathname.indexOf('/pegassure-logbook/');
    return (
        <li onClick={() => { props.navigateTo(routes.SCAN_LOGBOOK_QR); props.frontlineUserPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.SCAN_LOGBOOK_QR || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_tickets_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_tickets_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> Scan for Logbook Review </span>
            </a>
        </li>

    )
}

const Users = (props) => {

    // if (props.props.user.role === userRoles.SUPER_ADMIN) {
    const index = props.props.location.pathname.indexOf('/user/');
    return (
        <li onClick={() => { props.navigateTo(routes.USER); props.userPageClicked(0) }} data-test="users_main_menu">
            <a className={((props.props.location.pathname === routes.USER || (props.props.location.pathname === routes.NEW_USER) || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_users.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_users_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label">Users</span>
            </a>
        </li>

    )
    // }
}

const HomePage = (props) => {
    const index = props.props.location.pathname.indexOf('/home/');
    return (
        <li onClick={() => { props.navigateTo(routes.HOMEPAGE); props.frontlineUserPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.HOMEPAGE || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_home1_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_home1_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> HOME </span>
            </a>
        </li>

    )
}

const frontLinePriorityEscalation = (props) => {
    const index = props.props.location.pathname.indexOf('/tickets/priority-escalation-tickets/');
    return (
        <li onClick={() => { props.navigateTo(routes.PRIORITY_ESCALATION_TICKETS); props.frontlineUserPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.PRIORITY_ESCALATION_TICKETS || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_tickets_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_tickets_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> PegAssure Tickets </span>
            </a>
        </li>

    )
}

const UserProfile = (props) => {
    const index = props.props.location.pathname.indexOf('/user_profile/');
    return (
        <li onClick={() => { props.navigateTo(routes.USER_PROFILE); props.frontlineUserPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.USER_PROFILE || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_user_gray_short.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_user_white_short.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> User Profile </span>
            </a>
        </li>

    )
}

const frontlineScheduledTask = (props) => {
    const index = props.props.location.pathname.indexOf('/scheduled-tasks/');
    return (
        <li onClick={() => { props.navigateTo(routes.SCHEDULED_TASKS); props.scheduledTaskListPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.SCHEDULED_TASKS || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_inspection_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_inspection_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> Scheduled Tasks </span>
            </a>
        </li>

    )
}

const myTimesheetList = (props) => {
    const index = props.props.location.pathname.indexOf('/time-card-list/');
    return (
        <li onClick={() => { props.navigateTo(routes.FL_TIME_SHEET_LIST); props.timeSheetListInfoPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.FL_TIME_SHEET_LIST || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_clock1_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_clock1_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> My Timesheet List </span>
            </a>
        </li>
    )
}

const myTimecardReviewList = (props) => {
    const index = props.props.location.pathname.indexOf('/timecard-review-list/');
    return (
        <li onClick={() => { props.navigateTo(routes.FL_TIMECARD_REVIEWED_LIST); props.timecardReviewedListPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.FL_TIMECARD_REVIEWED_LIST || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_stopwatch_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_stopwatch_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> My Timecard Requests </span>
            </a>
        </li>
    )
}

const myTimecardCorrectionForm = (props) => {
    const index = props.props.location.pathname.indexOf('/timecard-correction/');
    return (
        <li onClick={() => { props.navigateTo(routes.TIMECARD_CORRECTION_MODULE_V3); }}>
            <a className={((props.props.location.pathname === routes.TIMECARD_CORRECTION_MODULE_V3 || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_clock2_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_clock2_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> Timecard Correct Form </span>
            </a>
        </li>
    )
}

const frontlineScheduledTickets = (props) => {
    const index = props.props.location.pathname.indexOf('/myTicket/');
    return (
        <li onClick={() => { props.navigateTo(routes.FLUSER_MYTICKET); props.flUserSTMyTicketsPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.FLUSER_MYTICKET || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_ticket_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_ticket_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> My Tickets </span>
            </a>
        </li>
    )
}

const frontlineShifts = (props) => {
    const index = props.props.location.pathname.indexOf('/shift/');
    return (
        <li onClick={() => { props.navigateTo(routes.SHIFT); props.shiftScheduleListPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.SHIFT || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_shift_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_shift_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> My Shifts </span>
            </a>
        </li>

    )
}

const frontlineSwapRequestList = (props) => {
    const index = props.props.location.pathname.indexOf('/shift-swap/list/');
    return (
        <li onClick={() => { props.navigateTo(routes.SWAP_LIST); props.swapListPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.SWAP_LIST || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_swap1_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_swap1_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> My Swap Requests  </span>
            </a>
        </li>

    )
}

const frontlineOverrideAvailabilityRequestList = (props) => {
    const index = props.props.location.pathname.indexOf('/override-availability-list/');
    return (
        <li onClick={() => { props.navigateTo(routes.OVERRIDE_AVAILABILITY_REQUEST_LIST); props.overrideAvailabilityRequestListPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.OVERRIDE_AVAILABILITY_REQUEST_LIST || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_override_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_override_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> My Override Requests  </span>
            </a>
        </li>

    )
}

const AvailabilityPlanner = (props) => {

    // if (props.props.user.role === userRoles.SUPER_ADMIN) {
    const index = props.props.location.pathname.indexOf('/availability/');
    return (
        <li onClick={() => props.navigateTo(routes.FLUSER_CALENDAR_AVAILABILITY)} data-test="users_main_menu">
            <a className="app-menu__item" href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_planner_calendar_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_success_calendar_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> My Availability Calendar </span>
            </a>
        </li>

    )
    // }
}

const ShiftPlanner = (props) => {

    // if (props.props.user.role === userRoles.SUPER_ADMIN) {
    const index = props.props.location.pathname.indexOf('/shift/');
    return (
        <li onClick={() => props.navigateTo(routes.FLUSER_CALENDAR_SHIFT)} data-test="users_main_menu">
            <a className="app-menu__item" href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_planner_calendar_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_success_calendar_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> My Shift Calendar </span>
            </a>
        </li>

    )
    // }
}

const Privacy_Policy = () => {
    return (
        <li>
            <a className="app-menu__item" href="https://pegasusclean.com/privacy-policy/">
                <span className="icons_gray">
                    <img src="/images/icons/icn_survey1_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_survey1_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> Privacy Policy </span>
            </a>
        </li>

    )
}

const About_Us = () => {
    return (
        <li>
            <a className="app-menu__item" href="https://pegasusclean.com/about-us/">
                <span className="icons_gray">
                    <img src="/images/icons/icn_about_gray_short.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_about_white_short.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> About Us </span>
            </a>
        </li>

    )
}

const Logout = (props) => {
    return (
        <li className="" onClick={props.props.logout}>
            <a className="app-menu__item" href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_logout1_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_logout1_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label">Log Out</span>
            </a>
        </li>
    )
}

const InspectionForms = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.INSPECTION_FORMS); props.inspectionFormPageClicked(0) }} data-test="inspection_forms_btn">Inspection Forms</a></li>
    )
}

const Ratings = (props) => {
    return (
        <li onClick={() => { props.ratingPageClicked(0, 10); props.getRatings({ hide: false }); props.changeHideStatus(true) }}><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => props.navigateTo(routes.RATINGS)} data-test="rating_navigate_btn">Ratings</a></li>
    )
}

const Categories = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => props.navigateTo(routes.CATEGORIES)}>Categories</a></li>
    )
}

const PositionsAndRegions = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => props.navigateTo(routes.NEW_POSITIONS_AND_REGIONS)}>Positions And Regions</a></li>
    )
}

const PegAssure = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => props.navigateTo(routes.PEGASSURE)}>PegAssure</a></li>
    )
}

const SettingsPreferences = (props) => {
    return (
        <li style={props.props.user.enable_preferences ? null : { pointerEvents: 'none' }}>
            <a style={props.props.user.enable_preferences ? null : { color: 'grey' }} className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => props.navigateTo(routes.SETTINGS_PREFERENCES)}>Email Preferences</a>
        </li>
    )
}
const SettingInsights = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => props.navigateTo(routes.INSIGHTS)}>PegAssure</a></li>
    )
}

const PegAssureFeedback = (props) => {
    // if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.ADMIN) {
    const index = props.props.location.pathname.indexOf('/feedback-table/');
    return (
        <li onClick={() => { props.navigateTo(routes.FEEDBACK_TABLE); props.companyPageClicked(0) }}>
            <a className={(((props.props.location.pathname === routes.FEEDBACK_TABLE) || (props.props.location.pathname === routes.FEEDBACK_TABLE) || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_companies_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_companies_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label">PegAssure Feedback </span>
            </a>
        </li>

    )
    // }
}

const PriorityEscalationTicketsForm = (props) => {
    const index = props.props.location.pathname.indexOf('/priority-escalation-tickets/');
    return (
        <li id='glowAnimation' onClick={() => { props.navigateTo(routes.PRIORITY_ESCALATION_TICKETS); props.companyPageClicked(0) }}>
            <a className={(((props.props.location.pathname === routes.PRIORITY_ESCALATION_TICKETS) || (props.props.location.pathname === routes.PRIORITY_ESCALATION_TICKETS) || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_inspection_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_inspection_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label">PegAssure Tickets</span>
            </a>
        </li>

    )
}

const Matrix = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.Standard_Matrix); props.companyPageClicked(0) }} data-test="inspection_forms_btn">Matrix</a></li>
    )
}

const renderSettingsModule = (props) => {

    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        return (
            <>
                {SkillSet(props)}
                {HoursType(props)}
                {Department(props)}
                {InspectionForms(props)}
                {Ratings(props)}
                {Categories(props)}
                {Criteria(props)}
                {PositionsAndRegions(props)}

                {/* {PegAssure(props)} */}
                {SettingsPreferences(props)}
                {props.props.user.selected_mode && props.props.user.selected_mode === 'external' ? SettingInsights(props) : ''}
                {PegassurePlaybookList(props)}
                {Matrix(props)}
            </>
        )

    } else if (props.props.user.role === userRoles.ADMIN) {

        return (
            <>
                {/* {InspectionForms(props)} */}
                {/* {Ratings(props)} */}
                {/* {Categories(props)} */}
                {/* {PositionsAndRegions(props)} */}
                {SettingsPreferences(props)}
            </>
        )
    } else if (props.props.user.role === userRoles.INSPECTOR) {
        return (
            <>
                {SettingsPreferences(props)}
            </>
        )
    } else if (props.props.user.role === userRoles.CLIENT) {
        return (
            <>
                {SettingsPreferences(props)}
            </>
        )
    } else if (props.props.user.role === userRoles.REQUESTOR) {
        return (
            <>
                {SettingsPreferences(props)}
            </>
        )
    }

}

const KpiInspection = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.INSPECTION_FORMS); props.inspectionFormPageClicked(0) }} data-test="inspection_forms_btn">Inspection Forms</a></li>
    )
}

const KpiRatings = (props) => {
    return (
        <li onClick={() => { props.ratingPageClicked(0, 10) }}><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => props.navigateTo(routes.RATINGS)} data-test="rating_navigate_btn">Ratings</a></li>
    )
}

const renderKpiModule = (props) => {
    if (props.props.user.role === userRoles.SUPER_ADMIN) {
        return (
            <>
                {KpiInspection(props)}
                {KpiRatings(props)}
                {props.props.user.selected_mode && props.props.user.selected_mode === 'external' ? SettingInsights(props) : ''}
            </>
        )

    }
}

const Settings = (props) => {

    // if (props.props.user.role === userRoles.SUPER_ADMIN || props.props.user.role === userRoles.CLIENT || props.props.user.role === userRoles.REQUESTOR) {
    const index = props.props.location.pathname.indexOf('/settings/');
    return (
        <li className={props.props.toggleSettings ? "treeview is-expanded" : "treeview"}>
            <a className={!props.settingActive || index > -1 ? "app-menu__item active" : "app-menu__item"} href="#settings" data-toggle="treeview" onClick={props.settingsClicked}>
                <span className="icons_gray">
                    <img src="/images/icons/icn_settings_gray.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_settings_white.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label" data-test="settings_navigate_btn">Settings</span>
                <i className="treeview-indicator fa fa-angle-down"></i>
            </a>
            <ul className="treeview-menu" data-test="settings_tree_view">
                {renderSettingsModule(props)}
            </ul>
        </li>
        // <li className="setting_dropdown">
        //     <div id="accordion" class="cst_accordion_menu">
        //         <div class="card-header" id="headingOne">
        //             <a className={(index > -1) ? "app-menu__item settings_menu active" : "app-menu__item settings_menu"} href="javascript:void(0)" class="app-menu__item settings_menu" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        //                 <span class="icons_gray">
        //                     <img src="/images/icons/icn_settings_gray.png" className="app-menu__icon" />
        //                 </span>
        //                 <span class="icons_white">
        //                     <img src="/images/icons/icn_settings_white.png" className="app-menu__icon" />
        //                 </span>
        //                 <span className="app-menu__label">Settings</span>
        //             </a>
        //         </div>
        //         <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
        //             <div class="card-body">
        //                 <ul class="inner_items">
        //                     <li> <a className={(props.props.location.pathname === routes.INSPECTION_FORMS) || (props.props.location.pathname === routes.NEW_INSPECTION_FORMS) ? "dropdown-item active" : "dropdown-item"} onClick={() => props.navigateTo(routes.INSPECTION_FORMS)} href="#">Inspection Forms</a></li>
        //                     <li> <a className={(props.props.location.pathname === routes.RATINGS) || (props.props.location.pathname === routes.NEW_RATING) ? "dropdown-item active" : "dropdown-item"} href="#" onClick={() => props.navigateTo(routes.RATINGS)} >Ratings</a></li>
        //                     <li> <a className={props.props.location.pathname === routes.NEW_POSITIONS_AND_REGIONS ? "dropdown-item active" : "dropdown-item"} href="#" onClick={() => props.navigateTo(routes.NEW_POSITIONS_AND_REGIONS)}>Positions And Regions</a></li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>
        // </li>

    )
    // }
}


// const KpiLink = (props) => {

//     const index = props.props.location.pathname.indexOf('/kpi/');
//     return (
//         <li className={props.props.toggleKpi ? "kpitreeview is-expanded" : "kpitreeview"}>
//             <a className={props.settingActive || index > -1 ? "app-menu__item active" : "app-menu__item"} href="#settings" data-toggle="kpitreeview" onClick={props.kpiClicked}>
//                 <span className="icons_gray">
//                     <img src="/images/icons/icn_settings_gray.png" className="app-menu__icon" />
//                 </span>
//                 <span className="icons_white">
//                     <img src="/images/icons/icn_settings_white.png" className="app-menu__icon" />
//                 </span>
//                 <span className="app-menu__label" data-test="settings_navigate_btn">KPI</span>
//                 <i className="treeview-indicator fa fa-angle-down"></i>
//             </a>
//             <ul className="treeview-menu" data-test="settings_tree_view">
//                 {renderKpiModule(props)}
//             </ul>
//         </li>
//        )
// }

/** ASSETS FLOW */
const AssetListPage = (props) => {
    return (
        <li>
            <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
                props.navigateTo(routes.ASSETS_LIST)
            }} data-test="inspection_forms_btn"> Asset List </a>
        </li>
    )
}

const AssetCheckOut = (props) => {
    return (
        <li>
            <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
                props.navigateTo(routes.ASSET_SCAN_CHECK_OUT_QR)
            }} data-test="inspection_forms_btn"> Asset Check Out </a>
        </li>
    )
}

const AssetCheckIn = (props) => {
    return (
        <li>
            <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
                props.navigateTo(routes.ASSET_SCAN_CHECK_IN_QR)
            }} data-test="inspection_forms_btn"> Asset Check In </a>
        </li>
    )
}

const renderAssetSubModule = (props) => {

    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor") {
            return (
                <div style={{ backgroundColor: '#232323' }}>
                    {AssetListPage(props)}
                    {AssetCheckOut(props)}
                    {AssetCheckIn(props)}
                </div>
            )
        }

    }

}

const AssetContainer = (props) => {
    // const index = props.props.location.pathname.indexOf('/inventory');
    const [openAssetContainer, setOpenAssetContainer] = useState(false);
    return (
        <li className={openAssetContainer ? "treeview is-expanded" : "treeview"}>
            <a className={openAssetContainer ? "dropdown-item treeview-item inventorySideBar" : "dropdown-item treeview-item"} href="#inventory" onClick={() => setOpenAssetContainer(!openAssetContainer)}>
                <span className="app-menu__label" data-test="settings_navigate_btn"> Assets </span>
                <i className={openAssetContainer ? "treeview-indicator fa fa-angle-down" : "treeview-indicator fa fa-angle-up"}></i>
            </a>
            <Collapse in={openAssetContainer}>
                <div id="example-collapse-text">
                    <ul className="treeview-menu" data-test="settings_tree_view">
                        {renderAssetSubModule(props)}
                    </ul>
                </div>
            </Collapse>
        </li>
    )
}

/** INVENTORY FLOW */
const InventoryListPage = (props) => {
    return (
        <li>
            <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
                props.navigateTo(routes.INVENTORY_MAIN_LIST);
            }} data-test="inspection_forms_btn"> Inventory List </a>
        </li>
    )
}

const Location = (props) => {
    return (
        <li>
            <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
                props.navigateTo(routes.LOCATION);
            }} data-test="inspection_forms_btn"> Location </a>
        </li>
    )
}

const Box = (props) => {
    return (
        <li>
            <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
                props.navigateTo(routes.BOX)
            }} data-test="inspection_forms_btn"> Box </a>
        </li>
    )
}

const PickingTickets = (props) => {
    return (
        <li>
            <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
                props.navigateTo(routes.PICKING_TICKETS)
            }} data-test="inspection_forms_btn"> Picking Tickets </a>
        </li>
    )
}

// const PickupsTickets = (props) => {
//     return (
//         <li>
//             <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
//                 props.navigateTo(routes.PICKUPS_TICKETS)
//             }} data-test="inspection_forms_btn"> Pickups Tickets </a>
//         </li>
//     )
// }

const EmergencyPickups = (props) => {
    return (
        <li>
            <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
                props.navigateTo(routes.EMERGENCY_PICKING_TICKETS)
            }} data-test="inspection_forms_btn"> Emergency Pickups </a>
        </li>
    )
}

const InventoryPullRequestForm = (props) => {
    return (
        <li>
            <a className="dropdown-item treeview-item" style={{ color: '#fff0f6' }} href="Javascript:void(0)" onClick={() => {
                props.navigateTo(routes.NEW_INVENTORY_PULL_REQUEST_TICKET);
            }} data-test="inspection_forms_btn"> Pull Request Form </a>
        </li>
    )
}

const renderInventorySubModule = (props) => {

    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "manager") {
            return (
                <div>
                    {AssetListPage(props)}
                    {InventoryListPage(props)}
                    {Box(props)}
                    {Location(props)}
                    {PickingTickets(props)}
                    {InventoryPullRequestForm(props)}

                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager") {
            return (
                <div>
                    {AssetListPage(props)}
                    {InventoryListPage(props)}
                    {Box(props)}
                    {Location(props)}
                    {PickingTickets(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor") {
            return (
                <div>
                    {AssetContainer(props)}
                    {InventoryListPage(props)}
                    {Box(props)}
                    {Location(props)}
                    {PickingTickets(props)}
                    {InventoryPullRequestForm(props)}

                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "approver") {
            return (
                <div>
                    {AssetListPage(props)}
                    {InventoryListPage(props)}
                    {Box(props)}
                    {Location(props)}
                    {PickingTickets(props)}
                    {EmergencyPickups(props)}
                </div>
            )
        }

    } else if (props.props.user.role === userRoles.ADMIN) {

        if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "manager") {
            return (
                <div>
                    {PickingTickets(props)}
                    {InventoryPullRequestForm(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager") {
            return (
                <div>
                    {AssetListPage(props)}
                    {InventoryListPage(props)}
                    {Box(props)}
                    {PickingTickets(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor") {
            return (
                <div>
                    {AssetCheckOut(props)}
                    {AssetCheckIn(props)}
                    {PickingTickets(props)}
                    {InventoryPullRequestForm(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "approver") {
            return (
                <div>
                    {PickingTickets(props)}
                    {EmergencyPickups(props)}
                </div>
            )
        }

    } else if (props.props.user.role === userRoles.REQUESTOR) {

        if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "manager") {
            return (
                <div>
                    {PickingTickets(props)}
                    {InventoryPullRequestForm(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager") {
            return (
                <div>
                    {AssetListPage(props)}
                    {InventoryListPage(props)}
                    {Box(props)}
                    {PickingTickets(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor") {
            return (
                <div>
                    {AssetCheckOut(props)}
                    {AssetCheckIn(props)}
                    {PickingTickets(props)}
                    {InventoryPullRequestForm(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "approver") {
            return (
                <div>
                    {PickingTickets(props)}
                    {EmergencyPickups(props)}
                </div>
            )
        }

    } else if (props.props.user.role === userRoles.INSPECTOR) {

        if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "manager") {
            return (
                <div>
                    {PickingTickets(props)}
                    {InventoryPullRequestForm(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "fulfillmentManager") {
            return (
                <div>
                    {AssetListPage(props)}
                    {InventoryListPage(props)}
                    {Box(props)}
                    {PickingTickets(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "supervisor") {
            return (
                <div>
                    {AssetCheckOut(props)}
                    {AssetCheckIn(props)}
                    {PickingTickets(props)}
                    {InventoryPullRequestForm(props)}
                </div>
            )
        } else if (props.inventoryRole && props.inventoryRole.length > 0 && props.inventoryRole == "approver") {
            return (
                <div>
                    {PickingTickets(props)}
                    {EmergencyPickups(props)}
                </div>
            )
        }

    }

}

const Inventory = (props) => {
    const index = props.props.location.pathname.indexOf('/inventory/');
    return (
        <li className={props.props.toggleInventoryTab ? "treeview is-expanded" : "treeview"}>
            <a className={props.settingActive || index > -1 ? "app-menu__item active" : "app-menu__item"} href="#inventory" data-toggle="treeview" onClick={props.inventoryTabClicked}>
                <span className="icons_gray">
                    <img src="/images/icons/icn_inventory_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_inventory_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label" data-test="settings_navigate_btn">Inventory</span>
                <i className="treeview-indicator fa fa-angle-down"></i>
            </a>
            <ul className="treeview-menu" data-test="settings_tree_view">
                {renderInventorySubModule(props)}
            </ul>
        </li>
    )
}

// TIME_TRACKER PAYROLL FLOW

const SkillSet = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.SKILLSET); }} data-test=""> Skill Sets </a></li>
    )
}

const HoursType = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.HOURSTYPE); }} data-test=""> Hours type </a></li>
    )
}

const Department = (props) => {
    return (
        <li><a className="dropdown-item treeview-item" href="Javascript:void(0)" onClick={() => { props.navigateTo(routes.DEPARTMENT); }} data-test=""> Departments </a></li>
    )
}

const LeavesList = (props) => {
    const index = props.props.location.pathname.indexOf('/leaves/');
    return (
        <li onClick={() => { props.navigateTo(routes.LEAVE_LIST); props.frontlineUserPageClicked(0) }}>
            <a className={((props.props.location.pathname === routes.USER_PROFILE || index > -1) && !props.settingActive) ? "app-menu__item active" : "app-menu__item"} href="javascript:void(0)">
                <span className="icons_gray">
                    <img src="/images/icons/icn_leaves_gray_24.png" className="app-menu__icon" />
                </span>
                <span className="icons_white">
                    <img src="/images/icons/icn_leaves_white_24.png" className="app-menu__icon" />
                </span>
                <span className="app-menu__label"> Leave Requests </span>
            </a>
        </li>

    )
}

const RenderSideBarContent = (props) => {
    if (props.props.user.role === userRoles.SUPER_ADMIN) {

        return (
            <>
                {Dashboard(props)}
                {Inspections(props)}
                {Tickets(props)}
                {props.props.user.safety_access ? Safety(props) : ''}
                {/* {PegLogbookQRScan(props)} */}
                {PegassureLogbookLogs(props)}
                {PegAssureFeedback(props)}
                {PriorityEscalationTicketsForm(props)}
                {Inventory(props)}
                {LeavesList(props)}
                {os1(props)}
                {Accounts(props)}
                {Quotes(props)}
                {Companies(props)}
                {Schedules(props)}
                {/*{KpiLink(props)}*/}
                {Users(props)}
                {props.props.user.selected_mode && props.props.user.selected_mode === 'external' ? Frontline(props) : ''}
                {Reports(props)}
                {props.props.user.selected_mode && props.props.user.selected_mode === 'external' ? CHI_Servy(props) : ''}
                {Settings(props)}
                {NewVerion(props)}

            </>
        )

    } else if (props.props.user.role === userRoles.ADMIN) {
        return (
            <>
                {Dashboard(props)}
                {Inspections(props)}
                {Tickets(props)}
                {props.props.user.safety_access ? Safety(props) : ''}
                {PegassureLogbookLogs(props)}
                {/* {PegLogbookQRScan(props)} */}
                {PegAssureFeedback(props)}
                {PriorityEscalationTicketsForm(props)}
                {Inventory(props)}
                {LeavesList(props)}
                {os1(props)}
                {/* {frontlineScheduledTask(props)} */}
                {Accounts(props)}
                {Schedules(props)}
                {props.props.user.selected_mode && props.props.user.selected_mode === 'external' ? Frontline(props) : ''}
                {Quotes(props)}
                {Companies(props)}
                {props.props.user.selected_mode && props.props.user.selected_mode === 'external' ? CHI_Servy(props) : ''}
                {Settings(props)}
                {NewVerion(props)}

            </>
        )
    } else if (props.props.user.role === userRoles.REQUESTOR) {
        return (
            <>
                {Dashboard(props)}
                {Inspections(props)}
                {Tickets(props)}
                {props.props.user.safety_access ? Safety(props) : ''}
                {PegassureLogbookLogs(props)}
                {PriorityEscalationTicketsForm(props)}
                {Inventory(props)}
                {os1(props)}
                {/* {Quotes(props)} */}
                {/* {PegLogbookQRScan(props)} */}
                {Settings(props)}
                {NewVerion(props)}

            </>
        )
    } else if (props.props.user.role === userRoles.CLIENT) {
        return (
            <>
                {Dashboard(props)}
                {Inspections(props)}

                {Tickets(props)}
                {/* {PriorityEscalationTicketsForm(props)} */}
                {Settings(props)}

            </>
        )
    } else if (props.props.user.role === userRoles.INSPECTOR) {
        return (
            <>
                {Inspections(props)}
                {Tickets(props)}
                {props.props.user.safety_access ? Safety(props) : ''}
                {Accounts(props)}
                {Schedules(props)}
                {PegassureLogbookLogs(props)}
                {PriorityEscalationTicketsForm(props)}
                {Inventory(props)}
                {os1(props)}
                {LeavesList(props)}
                {frontlineScheduledTask(props)}
                {/* {PegLogbookQRScan(props)} */}
                {Quotes(props)}
                {props.props.user.role === userRoles.INSPECTOR && props.props.isJobOwner.length ? Frontline(props) : ''}
                {Settings(props)}
                {NewVerion(props)}

            </>
        )
    } else if (props.props.user.role === userRoles.FRONTLINE) {
        let showLogbook = false;
        let localCamOption = JSON.parse(localStorage.getItem('user'))
        if (localCamOption.scan_type === 'clean') {
            showLogbook = false;
        } else {
            showLogbook = true;
        }
        return (
            <>
                {HomePage(props)}
                {/* {PegassureLogbookLogs(props)} */}
                {/* {PegLogbookQRScan(props)} */}
                {showLogbook ? PegassureLogbookLogs(props) : ''}
                {props.props.user.safety_access ? Safety(props) : ''}
                {frontLinePriorityEscalation(props)}
                {LeavesList(props)}
                {os1(props)}
                {frontlineScheduledTask(props)}
                {AvailabilityPlanner(props)}
                {frontlineOverrideAvailabilityRequestList(props)}
                {myTimesheetList(props)}
                {myTimecardReviewList(props)}
                {myTimecardCorrectionForm(props)}
                {frontlineScheduledTickets(props)}
                {frontlineShifts(props)}
                {ShiftPlanner(props)}
                {frontlineSwapRequestList(props)}
                {UserProfile(props)}
                {Privacy_Policy()}
                {About_Us()}
                {Logout(props)}

            </>
        )
    }
}

const SideBar = (props) => {

    if (props.props.token) {
        return (
            <>
                <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
                <aside className="app-sidebar">
                    <ul className="app-menu">
                        {RenderSideBarContent(props)}
                        {/* <li className="logout" onClick={props.props.logout}>
                            <a className="app-menu__item" href="javascript:void(0)">
                                <span className="icons_gray">
                                    <img src="/images/icons/icn_logout.png" className="app-menu__icon" />
                                </span>
                                <span className="icons_white">
                                    <img src="/images/icons/icn_logout_white.png" className="app-menu__icon" />
                                </span>
                                <span className="app-menu__label">Logout</span>
                            </a>
                        </li> */}

                    </ul>
                </aside>
            </>
        )
    }
}

const Footer = (props) => {

    const CurrentYear = new Date().getFullYear();
    if (props.props.token) {
        return (
            <footer className="cus_ffoter_block">
                <p className="footer_txt">{`© ${CurrentYear} Pegasus Inc. All Rights Reserved. ${process.env.REACT_APP_WEB_RELEASE_VERSION}`}</p>
            </footer>
        )
    } else if (!props.props.token && (props.props.location.pathname.includes(`signin`) || props.props.location.pathname.includes(`signup`) || props.props.location.pathname.includes(`forgetpassword`) || props.props.location.pathname.includes(`resetpassword`) || props.props.location.pathname === routes.HOME)) {
        return (
            <div className="login100-more">
                <div className="img-login">
                    <img src="/images/thumbnails/sign_in.png" alt="sign in" />
                </div>
                <div className="foot-note">
                    <h2>The Cutting Edge of Clean</h2>
                    <h5>The Leading Provider in Commercial Cleaning</h5>
                    <p>Pegasus Building Services is a leading provider of commercial cleaning and facility maintenance services in both commercial facilities and controlled environments throughout the southwestern region of the U.S. and beyond.</p>
                </div>
            </div>
        )
    } else {

        return (
            <></>
        )
    }
}

class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modeEvent: '',
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            inventoryRole: '',
        }
        $(window).focus(function () {
            const user = storage.get('user');
            props.updateAuthUser(user);
        });
    }

    onProfileClick = () => {
        this.props.onProfileClick();
    }

    navigateTo = (url) => {
        this.props.history.push(url)
    }


    settingsClicked = () => {
        this.props.history.push({
            search: '?settingActive=true'
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.settingsClicked();
    }

    customerClicked = () => {
        this.props.history.push({
            search: '?settingActive=true'
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.customerClicked();
    }

    kpiClicked = () => {
        this.props.history.push({
            search: '?settingActive=true'
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.kpiClicked();
    }

    inspectionsClicked = () => {
        this.props.history.push({
            search: '?settingActive=true'
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.inspectionsClicked();
    }
    ticketsClicked = () => {
        this.props.history.push({
            search: '?settingActive=true'
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.ticketsClicked();
    }

    frontlineClicked = () => {
        this.props.history.push({
            search: '?settingActive=true'
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.frontlineClicked();
    }

    pegAssureTabClicked = () => {
        this.props.history.push({
            search: '?settingActive=true'
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.pegAssureTabClicked();
    }
    modeChange = (event) => {
        this.setState({ modeEvent: event });
    }
    userModeClicked = (event) => {
        const user = storage.get('user');
        if (event.target.checked) {
            const mode = event.target.checked ? 'external' : 'internal';
            user['selected_mode'] = mode;
            storage.set('user', user);
            this.props.userModeClicked(user.id, mode);
        } else {
            const mode = this.state.modeEvent ? 'external' : 'internal';
            user['selected_mode'] = mode;
            storage.set('user', user);
            this.props.userModeClicked(user.id, mode);
        }
        this.props.closeModeModel();
    }

    inventoryTabClicked = () => {
        const isParams = this.props.location.search
        let router = 'settingActive=true'
        if (isParams) {
            router = isParams + `&${router}`
        }
        this.props.history.push({
            search: router
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.inventoryTabClicked();
    }

    teamManagementTabClicked = () => {
        this.props.history.push({
            search: '?settingActive=true'
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.teamManagementTabClicked();
    }

    os1Clicked = () => {

        const isParams = this.props.location.search
        let router = 'settingActive=true'
        if (isParams) {
            router = isParams + `&${router}`
        }
        this.props.history.push({
            search: router
        })

        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked();
        }

        this.props.os1Clicked();
    }

    // componentWillMount() {

    // }
    componentDidMount() {
        if (this.props.user && this.props.user.id) {
            this.props.getUpdatedCurrentUser(this.props.user.id)
        }
        if (!this.props.positionsList && this.props.user) {
            this.props.getPositions({ hide: false });
        }
        if (!this.props.isJobOwner.length && this.props.user) {
            this.props.getinspectorJobOwner(this.props.user.id)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user && this.props.user && prevProps.user.selected_mode !== this.props.user.selected_mode) {
            const current = this.props.location;
            this.props.history.replace('/reload');
            setTimeout(() => {
                this.props.history.replace(current);
            });
        }
        if (prevState.inventoryRole != storage.get('InventoryAccessRole')) {
            this.setState({ inventoryRole: storage.get('InventoryAccessRole') });
        }
    }

    toggleSideBarForMobileResonse = () => {
        // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        //  
        if (this.state.isMobile && this.props.toggleSideBar) {
            this.props.sideBarClicked()
        }
    }

    render() {
        
        const settingActive = queryString.parse(this.props.location.search);
        let chisurveyStarted = storage.get('chisurveyStarted');
        let faceDetectTrack = storage.get('FrontlineUser_FaceDetected');
        // let userMode
        // if (this.props.user) {
        //     userMode = this.props.user.selected_mode;
        // }
        if (this.props.token) {

            if (this.props && this.props.user.role === userRoles.CLIENT) {

                if ((convertedQuarterlyDate == quarterCheckDates.QUARTER1) || (convertedQuarterlyDate == quarterCheckDates.QUARTER2) ||
                    (convertedQuarterlyDate == quarterCheckDates.QUARTER3) || (convertedQuarterlyDate == quarterCheckDates.QUARTER4)) {

                    return (
                        <div className={"app sidebar-mini rtl dashboard_page_body"} onClick={this.toggleSideBarForMobileResonse}>
                            {chisurveyStarted == true ? null : (
                                <Header props={this.props} userMode={this.props.userMode && this.props.userMode === 'external' ? true : false} isMobile={this.state.isMobile} onProfileClick={this.onProfileClick} sideBarClicked={this.props.sideBarClicked} userModeClicked={this.userModeClicked} />
                            )}
                            {chisurveyStarted == true ? null : (
                                <SideBar
                                    {...this.props}
                                    settingActive={settingActive && settingActive.settingActive === "true" ? true : false}
                                    props={this.props}
                                    settingsClicked={this.settingsClicked}
                                    customerClicked={this.customerClicked}
                                    kpiClicked={this.kpiClicked}
                                    inspectionsClicked={this.inspectionsClicked}
                                    navigateTo={this.navigateTo}
                                    ticketsClicked={this.ticketsClicked}
                                    teamManagementTabClicked={this.teamManagementTabClicked}
                                    os1Clicked={this.os1Clicked}
                                    frontlineClicked={this.frontlineClicked}
                                    pegAssureTabClicked={this.pegAssureTabClicked}
                                    toggleSideBarForMobileResonse={this.toggleSideBarForMobileResonse}
                                    inventoryTabClicked={this.inventoryTabClicked}
                                    inventoryRole={this.state.inventoryRole}
                                />
                            )}
                            {this.props.children}
                            {this.props.modeModal ? <ModeModal openDialog={this.props.modeModal} modeChange={this.modeChange} userModeClicked={this.userModeClicked}></ModeModal> : null}
                            {this.props.profileModel ? <UpdateProfile /> : null}
                            <Footer props={this.props} />
                        </div>
                    )

                } else {

                    return (
                        <div className={"app sidebar-mini rtl dashboard_page_body"} onClick={this.toggleSideBarForMobileResonse}>
                            <Header props={this.props} userMode={this.props.userMode && this.props.userMode === 'external' ? true : false} isMobile={this.state.isMobile} onProfileClick={this.onProfileClick} sideBarClicked={this.props.sideBarClicked} userModeClicked={this.userModeClicked} />
                            <SideBar
                                {...this.props}
                                settingActive={settingActive && settingActive.settingActive === "true" ? true : false}
                                props={this.props}
                                settingsClicked={this.settingsClicked}
                                kpiClicked={this.kpiClicked}
                                inspectionsClicked={this.inspectionsClicked}
                                navigateTo={this.navigateTo}
                                ticketsClicked={this.ticketsClicked}
                                teamManagementTabClicked={this.teamManagementTabClicked}
                                os1Clicked={this.os1Clicked}
                                frontlineClicked={this.frontlineClicked}
                                pegAssureTabClicked={this.pegAssureTabClicked}
                                toggleSideBarForMobileResonse={this.toggleSideBarForMobileResonse}
                                inventoryTabClicked={this.inventoryTabClicked}
                                inventoryRole={this.state.inventoryRole}
                            />

                            {this.props.children}
                            {this.props.modeModal ? <ModeModal openDialog={this.props.modeModal} modeChange={this.modeChange} userModeClicked={this.userModeClicked}></ModeModal> : null}
                            {this.props.profileModel ? <UpdateProfile /> : null}
                            <Footer props={this.props} />
                        </div>
                    )

                }

            } else if (this.props.user.role === userRoles.FRONTLINE) {

                if (storage.get('isMealBreakInitiated')) {

                    return (
                        <div className={"app sidebar-mini rtl dashboard_page_body"} onClick={this.toggleSideBarForMobileResonse}>
                            <Header props={this.props} userMode={this.props.userMode && this.props.userMode === 'external' ? true : false} isMobile={this.state.isMobile} onProfileClick={this.onProfileClick} sideBarClicked={this.props.sideBarClicked} userModeClicked={this.userModeClicked} />
                            {/* <SideBar
                                {...this.props}
                                settingActive={settingActive && settingActive.settingActive === "true" ? true : false}
                                props={this.props}
                                settingsClicked={this.settingsClicked}
                                customerClicked={this.customerClicked}
                                kpiClicked={this.kpiClicked}
                                inspectionsClicked={this.inspectionsClicked}
                                navigateTo={this.navigateTo}
                                ticketsClicked={this.ticketsClicked}
                                teamManagementTabClicked={this.teamManagementTabClicked}
                                os1Clicked={this.os1Clicked}
                                frontlineClicked={this.frontlineClicked}
                                pegAssureTabClicked={this.pegAssureTabClicked}
                                toggleSideBarForMobileResonse={this.toggleSideBarForMobileResonse}
                                inventoryTabClicked={this.inventoryTabClicked}
                                inventoryRole={this.state.inventoryRole}
                            /> */}

                            {this.props.children}
                            {this.props.modeModal ? <ModeModal openDialog={this.props.modeModal} modeChange={this.modeChange} userModeClicked={this.userModeClicked}></ModeModal> : null}
                            {this.props.profileModel ? <UpdateProfile /> : null}
                            <Footer props={this.props} />
                        </div>
                    )

                } else {

                    return (
                        <div className={"app sidebar-mini rtl dashboard_page_body"} onClick={this.toggleSideBarForMobileResonse}>
                            <Header props={this.props} userMode={this.props.userMode && this.props.userMode === 'external' ? true : false} isMobile={this.state.isMobile} onProfileClick={this.onProfileClick} sideBarClicked={this.props.sideBarClicked} userModeClicked={this.userModeClicked} />
                            <SideBar
                                {...this.props}
                                settingActive={settingActive && settingActive.settingActive === "true" ? true : false}
                                props={this.props}
                                settingsClicked={this.settingsClicked}
                                customerClicked={this.customerClicked}
                                kpiClicked={this.kpiClicked}
                                inspectionsClicked={this.inspectionsClicked}
                                navigateTo={this.navigateTo}
                                ticketsClicked={this.ticketsClicked}
                                teamManagementTabClicked={this.teamManagementTabClicked}
                                os1Clicked={this.os1Clicked}
                                frontlineClicked={this.frontlineClicked}
                                pegAssureTabClicked={this.pegAssureTabClicked}
                                toggleSideBarForMobileResonse={this.toggleSideBarForMobileResonse}
                                inventoryTabClicked={this.inventoryTabClicked}
                                inventoryRole={this.state.inventoryRole}
                            />

                            {this.props.children}
                            {this.props.modeModal ? <ModeModal openDialog={this.props.modeModal} modeChange={this.modeChange} userModeClicked={this.userModeClicked}></ModeModal> : null}
                            {this.props.profileModel ? <UpdateProfile /> : null}
                            <Footer props={this.props} />
                        </div>
                    )

                }

            } else {

                return (
                    <div className={"app sidebar-mini rtl dashboard_page_body"} onClick={this.toggleSideBarForMobileResonse}>
                        <Header  props={this.props} userMode={this.props.userMode && this.props.userMode === 'external' ? true : false} isMobile={this.state.isMobile} onProfileClick={this.onProfileClick} sideBarClicked={this.props.sideBarClicked} userModeClicked={this.userModeClicked} />
                        <SideBar
                            {...this.props}
                            settingActive={settingActive && settingActive.settingActive === "true" ? true : false}
                            props={this.props}
                            settingsClicked={this.settingsClicked}
                            customerClicked={this.customerClicked}
                            kpiClicked={this.kpiClicked}
                            inspectionsClicked={this.inspectionsClicked}
                            navigateTo={this.navigateTo}
                            ticketsClicked={this.ticketsClicked}
                            teamManagementTabClicked={this.teamManagementTabClicked}
                            os1Clicked={this.os1Clicked}
                            frontlineClicked={this.frontlineClicked}
                            pegAssureTabClicked={this.pegAssureTabClicked}
                            toggleSideBarForMobileResonse={this.toggleSideBarForMobileResonse}
                            inventoryTabClicked={this.inventoryTabClicked}
                            inventoryRole={this.state.inventoryRole}
                        />

                        {this.props.children}
                        {this.props.modeModal ? <ModeModal openDialog={this.props.modeModal} modeChange={this.modeChange} userModeClicked={this.userModeClicked}></ModeModal> : null}
                        {this.props.profileModel ? <UpdateProfile /> : null}
                        <Footer props={this.props} />
                    </div>
                )

            }

        } else if (this.props.location.pathname === routes.THANKYOU || this.props.location.pathname === routes.CHI_REVIEW) {

            return this.props.children;

        } else {
            return (
                <div className="limiter">
                    <Header props={this.props} />
                    <div className="container-login100">
                        <div className="wrap-login100">
                            {this.props.children}
                            <Footer props={this.props} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    
    return {
        user: state.authReducer.user,
        token: state.authReducer.token,
        profileModel: state.authReducer.profileModel,
        account: state.adminOrSuperAdminReducer.account,
        company: state.adminOrSuperAdminReducer.company,
        ticket: state.adminOrSuperAdminReducer.ticket,
        toggleSideBar: state.adminOrSuperAdminReducer.toggleSideBar,
        toggleSettings: state.adminOrSuperAdminReducer.toggleSettings,
        toggleCustomer: state.adminOrSuperAdminReducer.toggleCustomer,
        inspectionToggleSettings: state.adminOrSuperAdminReducer.inspectionToggleSettings,
        ticketToggle: state.adminOrSuperAdminReducer.ticketToggle,
        pegAssureTabToggle: state.adminOrSuperAdminReducer.pegAssureTabToggle,
        toggleKpi: state.adminOrSuperAdminReducer.toggleKpi,
        totalQuotes: state.adminOrSuperAdminReducer.totalQuotes,
        totalLogbookLogs: state.adminOrSuperAdminReducer.totalLogbookLogs,
        totalRatings: state.adminOrSuperAdminReducer.totalRatings,
        totalInspectionForms: state.adminOrSuperAdminReducer.totalInspectionForms,
        totalCategories: state.adminOrSuperAdminReducer.totalCategories,
        totalUsers: state.adminOrSuperAdminReducer.totalUsers,
        totalAccounts: state.adminOrSuperAdminReducer.totalAccounts,
        totalTickets: state.adminOrSuperAdminReducer.totalTickets,
        prior_totalTickets: state.adminOrSuperAdminReducer.prior_totalTickets,
        totalInspections: state.adminOrSuperAdminReducer.totalInspections,
        totalCompanies: state.adminOrSuperAdminReducer.totalCompanies,
        modeModal: state.authReducer.modeModal,
        totalfrontline: state.adminOrSuperAdminReducer.totalfrontline,
        totalAccountUsers: state.adminOrSuperAdminReducer.totalAccountUsers,
        totalAccountAreas: state.adminOrSuperAdminReducer.totalAccountAreas,
        totalLogbookAreas: state.adminOrSuperAdminReducer.totalLogbookAreas,
        totalAccountHolidays: state.adminOrSuperAdminReducer.totalAccountHolidays,
        dashboardTicketCount: state.adminOrSuperAdminReducer.dashboardTicketCount,
        userMode: state.authReducer.user ? state.authReducer.user.selected_mode : null,
        dashboardQuoteCount: state.adminOrSuperAdminReducer.dashboardQuoteCount,
        companyName: state.adminOrSuperAdminReducer.companyName,
        totalCriteries: state.adminOrSuperAdminReducer.totalCriteries,
        currentAccount: state.adminOrSuperAdminReducer.currentAccount,
        isAccountOwner: state.adminOrSuperAdminReducer.isAccountOwner,
        totalFeedbacks: state.adminOrSuperAdminReducer.totalFeedbacks,
        scheduleTitle: state.adminOrSuperAdminReducer.scheduleTitle,
        scheduleTicketId: state.adminOrSuperAdminReducer.scheduleTicketId,
        scheduleTotalTickets: state.adminOrSuperAdminReducer.scheduleTotalTickets,
        previewScheduleTotalTickets: state.adminOrSuperAdminReducer.previewScheduleTotalTickets,
        total_company_access: state.adminOrSuperAdminReducer.total_company_access,
        box_total_count: state.InventoryReducer.box_total_count,
        total_inventoryItemsList_count: state.InventoryReducer.total_inventoryItemsList_count,
        pickingTicket_total_count: state.InventoryReducer.pickingTicket_total_count,
        pickupsTicket_total_count: state.InventoryReducer.pickupsTicket_total_count,
        toggleInventoryTab: state.InventoryReducer.toggleInventoryTab,
        assestStock_total_count: state.InventoryReducer.assestStock_total_count,
        assests_total_count: state.InventoryReducer.assests_total_count,

        total_skillSet_list: state.TimeTrackerModReducer.total_skillSet_list,
        total_isHourType_list: state.TimeTrackerModReducer.total_isHourType_list,
        total_department_list: state.TimeTrackerModReducer.total_department_list,
        total_leavesList_count: state.TimeTrackerModReducer.total_leavesList_count,
        total_scheduledTaskList_count: state.TimeTrackerModReducer.total_scheduledTaskList_count,
        scheduleTaskTitle: state.TimeTrackerModReducer.scheduleTaskTitle,
        scheduleTaskId: state.TimeTrackerModReducer.scheduleTaskId,
        total_subScheduledTaskList_count: state.TimeTrackerModReducer.total_subScheduledTaskList_count,
        frontlineToggle: state.adminOrSuperAdminReducer.frontlineToggle,
        positionsList: state.adminOrSuperAdminReducer.positionsList,
        total_shiftScheduleList_count: state.TimeTrackerModReducer.total_shiftScheduleList_count,
        total_shiftScheduleDetailList_count: state.TimeTrackerModReducer.total_shiftScheduleDetailList_count,
        total_shiftList_count: state.TimeTrackerModReducer.total_shiftList_count,
        total_swapList_count: state.TimeTrackerModReducer.total_swapList_count,
        total_overrideAvailabilityRequestList_count: state.TimeTrackerModReducer.total_overrideAvailabilityRequestList_count,
        total_availabilityList_count: state.TimeTrackerModReducer.total_availabilityList_count,
        teamManagementToggle: state.FormAuditModReducer.teamManagementToggle,
        os1Toggle: state.FormAuditModReducer.os1Toggle,
        total_checklistLog_count: state.FormAuditModReducer.total_checklistLog_count,
        total_checklistManager_count: state.FormAuditModReducer.total_checklistManager_count,
        total_checklistSupervisor_count: state.FormAuditModReducer.total_checklistSupervisor_count,
        total_checklist_count: state.FormAuditModReducer.total_checklist_count,
        total_trainingLog_count: state.FormAuditModReducer.total_trainingLog_count,
        total_restroomPortering_count: state.LogBookReducer.total_restroomPortering_count,
        total_requestform_count: state.TagModuleReducer.total_requestForm_count,
        deep_clean_count: state.projectModuleReducer.total_restroomPortering_count,
        stMyTicketsList_total_tickets: state.TimeTrackerModReducer.stMyTicketsList_total_tickets,
        timecardReviewedList_total_tickets: state.TimeTrackerModReducer.timecardReviewedList_total_tickets,
        timeSheetList_total_tickets: state.TimeTrackerModReducer.timeSheetList_total_tickets,
        total_jobcard_count: state.BuildingProfileReducer.total_jobcard_count,
        total_dailyKitting_count: state.InventoryModuleReducer.total_dailyKitting_count,
        total_buildingProfile_count: state.BuildingProfileReducer.total_buildingProfile_count,
        total_buildingProfile_count: state.BuildingProfileReducer.total_buildingProfile_count,
        total_performanceAuditList_count: state.auditsReducer.total_performanceAuditList_count,
        total_selfAuditList_count: state.auditsReducer.total_selfAuditList_count,
        performanceAuditEditId: state.auditsReducer.performanceAuditEditId,
        pegassurePlaybookList_total_tickets: state.PegassurePlaybookReducer.pegassurePlaybookList_total_tickets,
        playbookAreaHeaderUtils: state.PegassurePlaybookReducer.playbookAreaHeaderUtils,
        total_brmScheduleList_count: state.BRM.total_brmScheduleList_count,
        isStandardMatrix:state.BRM.isStandardMatrix,
        frontline_active_user_name: state.adminOrSuperAdminReducer.frontline_active_user_name,
        totalPayrollHolliday:state.TimeTrackerModReducer.total_count,
        totalHoursType:state.TimeTrackerModReducer.total_count,
        total_PCT_association_count:state.JobAssociateReducer?.totalTickets ?? 0,
        shareableId: state.adminOrSuperAdminReducer.shareableId,
        safetyToggle: state.safetyReducer.safetyToggle,
        isJobOwner:state.adminOrSuperAdminReducer.isJobOwner,
        safetyUnitManagement: state.safetyReducer.total_safetyUnitManagemnetList_count
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(actions.logout()),
        sideBarClicked: () => dispatch(actions.sideBarClicked()),
        onProfileClick: () => dispatch(actions.onProfileClick()),
        settingsClicked: () => dispatch(actions.settingsClicked()),
        customerClicked: () => dispatch(actions.customerClicked()),
        kpiClicked: () => dispatch(actions.kpiClicked()),
        userModeClicked: (user_id, mode) => dispatch(actions.loginUserModeSelection(user_id, mode)),
        closeModeModel: () => dispatch(actions.closeModeModel()),
        ticketPageClicked: (page) => dispatch(actions.ticketPageClicked(page)),
        companyPageClicked: (page) => dispatch(actions.companyPageClicked(page)),
        quotePageClicked: (page) => dispatch(actions.quotePageClicked(page)),
        inspectionPageClicked: (page) => dispatch(actions.inspectionPageClicked(page)),
        accountPageClicked: (page) => dispatch(actions.accountPageClicked(page)),
        frontlineUserPageClicked: (page) => dispatch(actions.frontlineUserPageClicked(page)),
        ratingPageClicked: (page, rows) => dispatch(actions.ratingPageClicked(page, rows)),
        userPageClicked: (page) => dispatch(actions.userPageClicked(page)),
        inspectionFormPageClicked: (page) => dispatch(actions.inspectionFormPageClicked(page)),
        changeHideStatus: (status) => dispatch(actions.changeHideStatus(status)),
        updateAuthUser: (user) => dispatch(actions.updateAuthUser(user)),
        getRatings: (data) => dispatch(actions.getRatings(data)),
        inspectionsClicked: () => dispatch(actions.inspectionsClicked()),
        ticketsClicked: () => dispatch(actions.ticketsClicked()),
        pegAssureTabClicked: () => dispatch(actions.pegAssureTabClicked()),
        resetInspectionSearch: () => dispatch(actions.resetInspectionSearch()),
        resetTicketSearch: () => dispatch(actions.resetTicketSearch()),
        updateLogbookSearchValues: (values) => dispatch(actions.updateLogbookSearchValues(values)),
        inventoryTabClicked: () => dispatch(actions.inventoryTabClicked()),
        frontlineClicked: () => dispatch(actions.frontlineClicked()),
        scheduledTaskListPageClicked: (page) => dispatch(actions.scheduledTaskListPageClicked(page)),
        getPositions: (data) => dispatch(actions.getPositions(data)),
        shiftScheduleListPageClicked: (page) => dispatch(actions.shiftScheduleListPageClicked(page)),
        swapListPageClicked: (page) => dispatch(actions.swapListPageClicked(page)),
        handleCancelClickAction: () => dispatch(actions.handleCancelClickAction()),
        overrideAvailabilityRequestListPageClicked: (page) => dispatch(actions.overrideAvailabilityRequestListPageClicked(page)),
        teamManagementTabClicked: () => dispatch(actions.teamManagementTabClicked()),
        os1Clicked: () => dispatch(actions.os1Clicked()),
        flUserSTMyTicketsPageClicked: (page) => dispatch(actions.flUserSTMyTicketsPageClicked(page)),
        timecardReviewedListPageClicked: (page) => dispatch(actions.timecardReviewedListPageClicked(page)),
        timeSheetListInfoPageClicked: (page) => dispatch(actions.timeSheetListInfoPageClicked(page)),
        performanceAuditPageClicked: () => dispatch(actions.performanceAuditPageClicked()),
        getUpdatedCurrentUser: (id) => dispatch(actions.getUpdatedCurrentUser(id)),
        safetyClicked: () => dispatch(actions.safetyClicked()),
        resetShiftScheduleDetailFilters: () => dispatch(actions.resetShiftScheduleDetailFilters()),
        resetShiftFilters: () => dispatch(actions.resetShiftFilters()),
        getinspectorJobOwner: (id) => dispatch(actions.getinspectorJobOwner(id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));